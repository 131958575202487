import styled from "styled-components";

export const CardItemContainer = styled.div`

.text-black{
  color:black;
}

.card{
  overflow:hidden;
}

.card .card-body{
  position: absolute;
}

.card .tag{
  position: absolute;
  right:0px;
}



.card:hover{
  background: rgba(0, 0, 0, 0.02);
  border: 1px solid rgba(0, 0, 0, 0.3);
  box-sizing: border-box;
  border-radius: 8px;
  padding:10px;
}

.btn{
  text-transform:capitalize;
  font-size: .75rem;
  line-height: 1.5;
  border-radius: .2rem;
}


.card-taps{
  position: absolute;
  left:12.5%;
  top:12.5%;
  right:0px;
}

.card:hover .card-taps{
  left:14%;
  top: 16%;
  right:0px;
}

.card:hover .no-profile-active{
  margin-bottom:15px;
  margin-left: 10px;
}

.card-expire{
  position: absolute;
  right:5%;
  top:5%;
}

.card:hover .card-expire {
  right:7%;
  top:7%;
}

.card-expire p{
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 12px;
}

.card-taps .title{
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}

.card-content{
  position: absolute;
  left:5%;
  bottom:0px;
  right:0px;
}






.shape{    
	border-style: solid; border-width: 0 70px 40px 0; float:right; height: 0px; width: 0px;
	-ms-transform:rotate(360deg); /* IE 9 */
	-o-transform: rotate(360deg);  /* Opera 10.5 */
	-webkit-transform:rotate(360deg); /* Safari and Chrome */
	transform:rotate(360deg);
}
.offer{
	background:#fff; border:1px solid #ddd; box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2); margin: 15px 0; overflow:hidden;
}
.shape {
	border-color: rgba(255,255,255,0) #d9534f rgba(255,255,255,0) rgba(255,255,255,0);
}
.offer-radius{
	border-radius:7px;
}
.offer-danger {	border-color: #d9534f; }
.offer-danger .shape{
	border-color: transparent #d9534f transparent transparent;
}
.offer-success {	border-color: #5cb85c; }
.offer-success .shape{
	border-color: transparent #5cb85c transparent transparent;
}
.offer-default {	border-color: #999999; }
.offer-default .shape{
	border-color: transparent #999999 transparent transparent;
}
.offer-primary {	border-color: #428bca; }
.offer-primary .shape{
	border-color: transparent #428bca transparent transparent;
}
.offer-info {	border-color: #5bc0de; }
.offer-info .shape{
	border-color: transparent #5bc0de transparent transparent;
}
.offer-warning {	border-color: #f0ad4e; }
.offer-warning .shape{
	border-color: transparent #f0ad4e transparent transparent;
}

.shape-text{
	color:#fff; font-size:8px; font-weight:bold; position:relative; right:-35px; top:2px; white-space: nowrap;
	-ms-transform:rotate(30deg); /* IE 9 */
	-o-transform: rotate(360deg);  /* Opera 10.5 */
	-webkit-transform:rotate(30deg); /* Safari and Chrome */
	transform:rotate(30deg);
}	
.offer-content{
	padding:0 20px 10px;
}








.caption-1 figcaption {
  position: absolute;
  bottom: 0;
  right: 0;
}

.jto-bottom-item {
  /* position: relative; */
}

.jto-top-item {
  position: absolute;
  top: 12.5%;
  left: 15%;
}



.required-field::after {
  content: "*";
  color: red;
  margin-left: 2px;
}


.marquee {
  margin: 0 auto;
  white-space: nowrap;
  overflow: hidden; 
  box-sizing: border-box;
  padding: 0;
  display: block;
}

@keyframes marquee {
  0% { transform: translate(100%, 0); -webkit-transform: translateX(100%); }
  100% { transform: translate(-100%, 0); -webkit-transform: translateX(-100%); }
}
.marquee span {
  display: inline-block;
  text-indent: 0;
  overflow: hidden;
  -webkit-transition: 15s;
  transition: 15s;
  -webkit-animation: marquee 15s linear infinite;
  animation: marquee 15s linear infinite;
}


/*  .sort-link {
  overflow: auto;
  white-space: nowrap;
} */ 

.caption-1{
  overflow: auto;
  white-space: nowrap;
}

.caption-1 figcaption {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}


.jto-card {
  font-weight: bold;
  text-align: right;
  color: #181a1a;
  box-shadow: 0 1px 5px #00000099;
  
  border-radius: 1rem !important;
  /* border-radius: 10px; */
  /* background-image: url(https://images.unsplash.com/photo-1557672199-6e8c8b2b8fff?ixlib=rb-1.2.1&auto=format&fit=crop&w=934&q=80);
  */
  background-size: cover; 
  
  position: relative;
  
  transition-duration: 300ms;
  transition-property: transform, box-shadow;
  transition-timing-function: ease-out;
  transform: rotate3d(0);
}

.jto-card:hover {
  transition-duration: 150ms;
  box-shadow: 0 5px 20px 5px #00000044;
}

.jto-card .glow {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  
  background-image: radial-gradient(circle at 50% -20%, #ffffff22, #0000000f);
}
.product {
  max-width: 1170px;
  margin: auto;
  /* -webkit-box-shadow: 0px 6px 60px -10px rgba(0,0,0,0.5);
  -moz-box-shadow: 0px 6px 60px -10px rgba(0,0,0,0.5);
  box-shadow: 0px 6px 60px -10px rgba(0,0,0,0.5); */
}

/* Product Slider */

/* .product-slider {
  min-height: 300px;
  height: 100%;
} */

.product-slider{
  border-radius: "5px";
}

.product-slider .item{
  padding: "20px";
}

/* Product Content */

.product-content {
  background-color: #ffffff;
}

.product-title {

  font-weight: 700;
  font-size: 36px;

  /* font-family: 'Roboto', sans-serif; */
  
}

.product-category {
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.product-price {


   
  font-weight: 500;
  font-size: 30px;
  /* margin: 10px 0; 
  font-family: 'Roboto', sans-serif; */
}

.product-description {
  margin: 10px 0;
}

.product-features {
  margin: 10px 0;
}

/* Product Color Selector */

.product-color-selector {
  width: 100%;
  float: left;
  margin: 10px 0;
}

.product-color-label {
  float: left;
  margin-top: 4px;
}

.product-colors {
  float: left;
  padding: 0 0 18px 12px;
  margin-bottom: 0;
}

.product-color {
  height: 30px;
  width: 30px;
  background: #cccccc;
  border-radius: 30px;
  display: inline-block;
  margin-right: 3px;
  -webkit-transition: all .2s ease-in-out;
  -o-transition: all .2s ease-in-out;
  transition: all .2s ease-in-out;
}

.product-color:hover {
  cursor: pointer;
}

.product-color-black {
  background: #00171F;
}

.product-color-navy {
  background: #1D2D44;
}

.product-color-grey {
  background: #E6E6E9;
}

.product-color_active {
-webkit-box-shadow: 0px 5px 15px -4px rgba(0,23,31,1);
-moz-box-shadow: 0px 5px 15px -4px rgba(0,23,31,1);
box-shadow: 0px 5px 15px -4px rgba(0,23,31,1);
}

/* Product Button */

.product-button {
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 1px;
  background: #FF2414;
  border: none;
  border-radius: 0;
  padding: 0.75rem 1.75rem;
  -webkit-box-shadow: 0px 5px 15px -4px rgba(255,36,20,1);
  -moz-box-shadow: 0px 5px 15px -4px rgba(255,36,20,1);
  box-shadow: 0px 5px 15px -4px rgba(255,36,20,1);
}

.product-button:hover {
  cursor: pointer;
  background: #E52012;
  -webkit-transform: scale(1.025);
  -moz-transform: scale(1.025);
  -ms-transform: scale(1.025);
  transform: scale(1.025);
}


.custom-separator {
  width: 5rem;
  height: 6px;
  border-radius: 1rem;
}
`;