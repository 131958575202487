import * as actionTypes from "../constants/checkoutConstants";

const CHECKOUT_INITIAL_STATE = {
  basicDetails: {},
  fullDetails:{}
};

export const checkoutReducer = (state = CHECKOUT_INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.SAVE_BASIC_DETAILS:
        return {
            ...state,
            basicDetails:action.payload
          };
    case actionTypes.SAVE_FULL_DETAILS:
        return {
                ...state,
                fullDetails:action.payload
              };
    case actionTypes.RESET_DETAILS:
        return {
                        ...state,
                        fullDetails:{},
                        basicDetails: {}
        };
    default:
        return state;
  }
};

export const placeOrderReducer = (state = { order : null,loading:false }, action) => {
  switch (action.type) {
    case actionTypes.PLACE_ORDER_REQUEST:
      return {
        ...state,
        loading: true,
        completed:false,
        //order: {},
      };
    case actionTypes.PLACE_ORDER_SUCCESS:
      return {
        ...state,
        order: action.payload,
        loading: false,
        completed:false,
      };
    case actionTypes.PLACE_ORDER_COMPLETED:
      return {
        ...state,
        //order: action.payload,
        completed:true,
        loading: false,
    };
    case actionTypes.PLACE_ORDER_FAIL:
      return {
        loading: false,
        error: action.payload,
        completed:false,
      };
    case actionTypes.PLACE_ORDER_RESET:
      return {
        order: null,
        completed:false,
      };
    default:
      return state;
  }
};


export const razorpayVerificationReducer = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.RAZORPAY_VERIFICATION_REQUEST:
      return {
        rvLoading: true,
        //rvData: {},
      };
    case actionTypes.RAZORPAY_VERIFICATION_SUCCESS:
      return {
        rvLoading: false,
        rvData:action.payload,
      };
    case actionTypes.RAZORPAY_VERIFICATION_FAIL:
      return {
        rvLoading: false,
        rvError: action.payload,
      };
    default:
      return state;
  }
};

