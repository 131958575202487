import * as actionTypes from "../constants/authConstants";
import axios from "axios";



export const clearUser = () => async (dispatch,getState) => {
  //localStorage.removeItem("user");
  localStorage.clear();
  dispatch({ type: actionTypes.GET_USER,user:null});
}



export const getUser = () => async (dispatch,getState) => {


  const tokenDetailsString = localStorage.getItem('auth');
  let tokenDetails = '';
  if (!tokenDetailsString) {
      console.log("getUser is null & logout");
      //dispatch(logout(history));
      //return;
  }

  tokenDetails = JSON.parse(tokenDetailsString);


  const userData = localStorage.getItem("auth")
  ? JSON.parse(localStorage.getItem("auth"))
  : {};

  //console.log("local json -"+localStorage.getItem("user"))
  console.log( "local user to json - "+ JSON.stringify(tokenDetails));

  console.log("1.0 user name - "+tokenDetails.user.name);
  console.log("2.0 user name - "+tokenDetails.user["name"]);

  dispatch({ type: actionTypes.GET_USER,payload:tokenDetails});
}



export const signUp = (user,city,occupation,company) => async (dispatch,getState) => {
  try {
    dispatch({ type: actionTypes.SIGNUP_REQUEST });


    let fd = new FormData();
    const idToken = await user.getIdToken(true);

    console.log("token - "+idToken);

    fd.append('id_token',idToken);
    fd.append('uid',user.uid);
    fd.append('email',user.email);
    fd.append('name',user.displayName);
    //fd.append('profile',user.photoURL);
    //fd.append('phone',phone);
    fd.append('city',city);
    fd.append('occupation',occupation);
    fd.append('company',company);

    fd.append('auth_type',"email");
    fd.append('role',"user");

    const config = {
      header: {
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.post("/api/auth/signUp",fd,config);

    if(data.status){

      const userData = {
        token:data.token,
        user:data.user,
        expireDate:data.expireDate
        // id: data.user.id,
        // uid:data.user.uid,
        // name:data.user.name,
        // email:data.user.email,
        // profile:data.user.profile
      }

      //localStorage.setItem("user", JSON.stringify(userData));



      localStorage.setItem("auth", JSON.stringify(userData));

      dispatch({ type: actionTypes.GET_USER,payload:userData});

      dispatch({
        type: actionTypes.SIGNUP_REQUEST_SUCCESS,
        payload: userData,
      });

      return true;

      //dispatch({ type: actionTypes.GET_USER,user:userData});
      
    }else{
      
      dispatch({
        type: actionTypes.SIGNUP_REQUEST_FAIL,
        payload: data.error
      });

      return false;
    }

    


  } catch (error) {
    dispatch({
      type: actionTypes.SIGNUP_REQUEST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
    return false;
  }
};



export const signIn = (user,authType) => async (dispatch,getState) => {
  try {
    dispatch({ type: actionTypes.SIGNIN_REQUEST });

    if(user){

      console.log("user found");
    
    let fd = new FormData();
    const idToken = await user.getIdToken(true);


    console.log("token - "+idToken);

    fd.append('id_token',idToken);
    fd.append('name',user.displayName);
    fd.append('email',user.email);
    fd.append('auth_type',authType);

    const config = {
      header: {
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.post("/api/auth/signIn",fd,config);

    if(data.status){

      console.log("sign in success");

      //localStorage.setItem("token", data.token);
      //localStorage.setItem("user", JSON.stringify(data.user));

      const userData = {
        token:data.token,
        user:data.user,
        expireDate:data.expireDate
        // id: data.user.id,
        // uid:data.user.uid,
        // name:data.user.name,
        // email:data.user.email,
        // profile:data.user.profile
      }

    
      //console.log("sign data - "+JSON.stringify(userData))

      localStorage.setItem("auth", JSON.stringify(userData));
      //console.log("sign read data - "+localStorage.getItem("user"))

      dispatch({ type: actionTypes.GET_USER,payload:userData});

      dispatch({
        type: actionTypes.SIGNIN_REQUEST_SUCCESS,
        payload: userData,
      });


    }else{

      console.log("sign in failed");

      dispatch({
        type: actionTypes.SIGNIN_REQUEST_FAIL,
        payload: data.error
      });
    }

  }else{
    console.log("user not found");
    dispatch({
      type: actionTypes.SIGNIN_REQUEST_FAIL,
      payload:"User not found"
    });
  }

    


  } catch (error) {
    dispatch({
      type: actionTypes.SIGNIN_REQUEST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};


export function logout(history) {
  console.log("logout start");
  localStorage.removeItem('auth');
  //history.push('/login');
  
  //redirect to home page if account page

  //history.push('/');
  return {
      type: actionTypes.LOGOUT_ACTION,
      payload:null
  };
}

export function loginConfirmedAction(data) {
  return {
      type: actionTypes.LOGIN_CONFIRMED_ACTION,
      payload: data,
  };
}

export function checkAutoLogin(dispatch, history) {

  console.log("checkAutoLogin");

  const tokenDetailsString = localStorage.getItem('auth');
  let tokenDetails = '';
  if (!tokenDetailsString) {
      console.log("tokenDetailsString is null & logout");
      dispatch(logout(history));
      return;
  }

  tokenDetails = JSON.parse(tokenDetailsString);
  let expireDate = new Date(tokenDetails.expireDate);
  let todaysDate = new Date();

  console.log("expireDate - "+tokenDetails.expireDate);

  if (todaysDate > expireDate) {
      console.log("todaysDate > expireDate & logout");
      //console.log("logout");
      dispatch(logout(history));
      return;
  }else{
      console.log("todaysDate < expireDate & login");
      console.log("login success");
  }

  console.log("login details - "+tokenDetails);

  dispatch({ type: actionTypes.GET_USER,payload:tokenDetails});
  dispatch(loginConfirmedAction(tokenDetails));

  const timer = expireDate.getTime() - todaysDate.getTime();
  console.log("checkAutoLogin timer - "+timer);
  //runLogoutTimer(dispatch, timer, history);
}



