export const SIGNUP_REQUEST = "SIGNUP_REQUEST";
export const SIGNUP_REQUEST_SUCCESS = "SIGNUP_REQUEST_SUCCESS";
export const SIGNUP_REQUEST_FAIL = "SIGNUP_REQUEST_FAIL";

export const SIGNIN_REQUEST = "SIGNIN_REQUEST";
export const SIGNIN_REQUEST_SUCCESS = "SIGNIN_REQUEST_SUCCESS";
export const SIGNIN_REQUEST_FAIL = "SIGNIN_REQUEST_FAIL";

export const GET_USER = "GET_USER";
export const CLEAR_USER = "CLEAR_USER";

//check login session
export const LOGOUT_ACTION = "LOGOUT_ACTION";
export const LOGIN_CONFIRMED_ACTION = "LOGIN_CONFIRMED_ACTION";
