import styled from "styled-components";

export const EditProfileContainer = styled.div`

.w-200 {
  width: 200px;
  margin: auto;
}

.mt-10 {
  margin-top: 10px
}

.avatar {
  vertical-align: middle;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
  border: 1px solid #cccccc;
}

.c-logo {
  object-fit: contain;
}

.card-2 .card-body{
  position: absolute;
}

.btn{
  text-transform:capitalize;
  font-size: .75rem;
  line-height: 1.5;
  border-radius: .2rem;
}

.card-taps{
  position: absolute;
  left:12.5%;
  top:12.5%;
  right:0px;
}

.card-content{
  position: absolute;
  left:5%;
  bottom:0px;
  right:0px;
}

.btn-activated{
  background: rgba(44, 180, 105, 0.1);
  color: #2CB469;
}

.btn-make-active{
  background: #2CB469;
  color: #ffffff;
}

.btn-delete{
  background:rgb(190 32 51 / 10%);
  color: #BE2033;
}

.profile-label{
  width:100%;
}



.profile-type{
  background: rgba(0, 0, 0, 0.02);
  border: 1px solid rgba(0, 0, 0, 0.3);
  box-sizing: border-box;
  border-radius: 8px;
}

.profile-type:hover {
  cursor: pointer;
}

.profile-type i{
  font-size: 20px;
  }

  .profile-type .bg-icon{
    font-size: 40px;
    position: absolute;
    opacity: 0.2;
    z-index: -1;
    right: 0%;
    bottom: 0%;
    }

  .profile-name{
    color: #1A1A1A;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
  }

.profile-type-input {
  display: none;
}

.check-item{
  width: 20px;
  height: 20px;
  display: inline-block;
  border: solid 2px #e3e3e3;
  border-radius: 50%;
  transition: 200ms linear;
  position: absolute;
  right:10px;
}

.check-icon:before {
  content: "";
  position: absolute;
  inset: 0;
  background-image: url("data:image/svg+xml,%3Csvg width='12' height='9' viewBox='0 0 12 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.93552 4.58423C0.890286 4.53718 0.854262 4.48209 0.829309 4.42179C0.779553 4.28741 0.779553 4.13965 0.829309 4.00527C0.853759 3.94471 0.889842 3.88952 0.93552 3.84283L1.68941 3.12018C1.73378 3.06821 1.7893 3.02692 1.85185 2.99939C1.91206 2.97215 1.97736 2.95796 2.04345 2.95774C2.11507 2.95635 2.18613 2.97056 2.2517 2.99939C2.31652 3.02822 2.3752 3.06922 2.42456 3.12018L4.69872 5.39851L9.58026 0.516971C9.62828 0.466328 9.68554 0.42533 9.74895 0.396182C9.81468 0.367844 9.88563 0.353653 9.95721 0.354531C10.0244 0.354903 10.0907 0.369582 10.1517 0.397592C10.2128 0.425602 10.2672 0.466298 10.3112 0.516971L11.0651 1.25003C11.1108 1.29672 11.1469 1.35191 11.1713 1.41247C11.2211 1.54686 11.2211 1.69461 11.1713 1.82899C11.1464 1.88929 11.1104 1.94439 11.0651 1.99143L5.06525 7.96007C5.02054 8.0122 4.96514 8.0541 4.90281 8.08294C4.76944 8.13802 4.61967 8.13802 4.4863 8.08294C4.42397 8.0541 4.36857 8.0122 4.32386 7.96007L0.93552 4.58423Z' fill='white'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-size: 12px;
  background-position: center center;
  transform: scale(1.6);
  transition: 200ms linear;
  opacity: 0;
}


.profile-type-input:checked + .profile-type {
   box-shadow: 0 0 1px 1px #17556d;
}

.profile-type-input:checked + .profile-type .check-item .float-left .check-icon {
  background: #17556D;
  border-color: #17556D;
  transform: scale(1.2);
}


.profile-type-input:checked + .profile-type .check-item .float-left .check-icon:before {
  transform: scale(1);
  opacity: 1;
  background-color: #17556D;
  border-color: #17556D;
  border-radius: 50%;
}


.border-md {
  border-width: 2px !important;
}

.separator {
  border-bottom: 1px dashed #aaa;
}

.text-small {
  font-size: 0.85rem;
}

@media (min-width: 576px) {
  .rounded-nav {
    border-radius: 50rem !important;
  }
}

@media (min-width: 576px) {
  .rounded-nav .nav-link {
    border-radius: 50rem !important;
  }
}

/* With arrow tabs */

.with-arrow .nav-link.active {
  position: relative;
}

.with-arrow .nav-link.active::after {
  content: '';
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid #2b90d9;
  position: absolute;
  bottom: -6px;
  left: 50%;
  transform: translateX(-50%);
  display: block;
}

/* lined tabs */

.lined .nav-link {
  border: none;
  border-bottom: 3px solid transparent;
}

.lined .nav-link:hover {
  border: none;
  border-bottom: 3px solid transparent;
}

.lined .nav-link.active {
  background: none;
  color: #555;
  border-color: #2b90d9;
}

/*
*
* ==========================================
* FOR DEMO PURPOSE
* ==========================================
*
*/



.nav-pills .nav-link {
  color: #555;
}
.text-uppercase {
  letter-spacing: 0.1em;
}

.banner {
  background: #a770ef;
  background: -webkit-linear-gradient(to right, #a770ef, #cf8bf3, #fdb99b);
  background: linear-gradient(to right, #a770ef, #cf8bf3, #fdb99b);
}

.card-preview .block .image img {
  width: 100%;
  border-radius: 8px;
}

.card-preview .hover-zoom img {
  transition: .3s ease;
}

/*slider switch css */
.theme-switch-wrapper {
  display: flex;
  align-items: center;
}
.theme-switch-wrapper em {
  margin-left: 10px;
  font-size: 1rem;
}

.theme-switch-wrapper i {
  margin-left: 5px;
  font-size: 1rem;
  font-style: italic;
}

.theme-switch {
  display: inline-block;
  height: 34px;
  position: relative;
  width: 60px;
}

.theme-switch input {
  display: none;
}

.theme-slider {
  background-color: #ccc;
  bottom: 0;
  cursor: pointer;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: 0.4s;
}

.theme-slider:before {
  background-color: #fff;
  bottom: 4px;
  content: "";
  height: 26px;
  left: 4px;
  position: absolute;
  transition: 0.4s;
  width: 26px;
}

input:checked + .theme-slider {
  background-color: #66bb6a;
}

input:checked + .theme-slider:before {
  transform: translateX(26px);
}

.theme-slider.round {
  border-radius: 34px;
}

.theme-slider.round:before {
  border-radius: 50%;
}


/*Profile*/

.profile-image {
  position: relative;
  display: inline-block;
  border-radius: 50%;
  padding: 0.5625rem;
  background-color: #f3f5f8;
  width: 11.25rem;
  height: 11.25rem;
}
.profile-image:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  box-shadow: inset 3px 3px 3px #cdcdcd, inset -3px -3px 3px #fafafa;
  border-radius: 50%;
  z-index: 0;
  left: 0;
}
.profile-image-border {
  box-shadow: 3px 3px 3px #cdcdcd, -3px -3px 3px #fafafa;
  border-radius: 50%;
  padding: 0.375rem;
  position: relative;
  z-index: 2;
}
.profile-image img {
  width: 9.375rem;
  height: 9.375rem;
  display: inline-block;
}
mark {
  color: inherit;
  padding: 0;
  background: 0 0;
  background-position-x: 0px;
  background-position-y: 0px;
  background-repeat: repeat;
  background-size: auto;
  background-image: linear-gradient(120deg,rgba(245, 108, 109,1) 0,rgba(245, 108, 109,1) 100%);
  background-repeat: no-repeat;
  background-size: 100% .28em;
  background-position: 0 70%;
  position: relative;
}
.main-nav ul{
  list-style: none;
}
.main-nav li a{
  display: block;
  padding: 0.5rem 1.5rem;
}
.main-nav li.active a.active .inner{
  box-shadow: inset 3px 3px 3px #cdcdcd, inset -3px -3px 3px #fafafa;
  color: red;
}
.header-search-form input[type="text"] {
  background: transparent;
  color: #ffffff;
  font-size: 0.8125rem;
  border: none;
  width: 100%;
  text-transform: uppercase;
  padding: 0.6875rem 1.5rem;
  font-weight: 600;
  color: #212529;
  outline: none;
}
.header-search-form input[type="text"]:focus {
  outline: none;
}
.header-search-form input[type="submit"] {
  height: 0;
  opacity: 0;
  overflow: hidden;
  padding: 0;
  width: 0;
  display: none;
}
.header-search-form .search-form-icon {
  color: #ffffff;
  font-size: 1.375rem;
  position: absolute;
  right: 1.5625rem;
  top: 0.625rem;
  cursor: pointer;
}

/*End profile*/



.hover-effect-container {
  position: absolute;
  top: -1.25rem;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  transition: 0.4s;
}
.hover-effect {
  position: relative;
  display: block;
  overflow: hidden;
}
.hover-effect-icon {
  display: inline-block;
  text-align: center;
  color: #fff;
  font-size: 0.75rem;   
}
.hover-effect:hover .hover-effect-container {
  top: 50%;
}
.hover-effect:before{
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  width: 100%;
  height:100%;
  background-color: rgba(52,58,64,0)
}
.hover-effect:hover:before{
background-color: rgba(52,58,64,.3)
}



`;