
import styled from "styled-components";
// import blackLineImg from "../../images/black-line.png";
// import logoImg from "../../images/logo.png";
// import circleBgImg from "../../images/circle-bg.png";
// import blueCardImg from "../../images/blue-card.png";


const blackLineImg = "https://dashboard.tap1ce.me/media/black-line.png";
const logoImg = "https://dashboard.tap1ce.me/media/logo.png";
const circleBgImg =  "https://dashboard.tap1ce.me/media/circle-bg.png";
const blueCardImg = "https://dashboard.tap1ce.me/media/blue-card.png";


//import herobg from "../../images/blue-card.png";


export const MainContainer = styled.div`

.card-desc
{
	
	float: left;
	height: 225px;
	overflow-y: scroll;
    margin-bottom:20px;
}

.card-desc::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: #F5F5F5;
}


.card-desc::-webkit-scrollbar
{
	width: 12px;
	background-color: #F5F5F5;
}

.card-desc::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #555;
}


/*COMMON CSS*/

a:focus,
input:focus,
textarea:focus,
button:focus,
select:focus {
    text-decoration: none;
    outline: none;
    -webkit-box-shadow: none;
    box-shadow: none;
}

input,
textarea,
button,
select {
    border: none;
}

input,
textarea,
select {
    width: 100%;
    background-color: #fff;
    padding: 0 25px;
    height: 70px;
    font-weight: 700;
    font-size: 15px;
    color: #616161;
}

::-webkit-input-placeholder {
    opacity: 1;
}

:-ms-input-placeholder {
    opacity: 1;
}

::-ms-input-placeholder {
    opacity: 1;
}

::placeholder {
    opacity: 1;
}

::-webkit-scrollbar {
    background-color: #ccc;
    width: 8px;
}

::-webkit-scrollbar-thumb {
    background-color: #245ca5;
}

a:focus,
a:hover {
    text-decoration: none;
}

i,
span,
a {
    display: inline-block;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 700;
    margin: 0px;
}

h1,
h1 a,
h2,
h2 a,
h3,
h3 a,
h4,
h4 a,
h5,
h5 a,
h6,
h6 a {
    color: #14212b;
}

h1 {
    font-size: 48px;
}

h2 {
    font-size: 36px;
}

h3 {
    font-size: 28px;
}

h4 {
    font-size: 22px;
}

h5 {
    font-size: 18px;
}

h6 {
    font-size: 16px;
}

ul,
ol {
    margin: 0px;
    padding: 0px;
    list-style-type: none;
}

p {
    margin: 0px;
    font-size: 16px;
    line-height: 27.2px;
    font-weight: 400;
}

a {
    -webkit-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
}

a,
a:hover {
    color: #00aae3;
}

/* header css */
body {
    font-family: "Poppins", sans-serif;
}

header {
    text-align: center;
    padding: 20px;
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
}

header img {
    width: 150px;
    object-fit: contain;
}


`


export const HomeContainer = styled.div`



.home {
  height: 100vh;
  overflow: hidden;
}

/* banner css*/
.banner {
  padding-top: 180px;
  background-image: url(assets/img/herobg.png);
  position: relative;
  background-size: cover;
}

.banner h1 {
  color: #fff;
  font-size: 65px;
  line-height: 78px;
  font-weight: 700;
  text-transform: capitalize;
  text-align: center;
  letter-spacing: -1.5px;
}

.banner p {
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  line-height: 27.2px;
  /*padding-top:50px;*/
  padding: 20px 50px;
  letter-spacing: 0.5px;
}

@keyframes mymove {
  50% {
      transform: rotate(180deg);
  }
}

.banner .banner_shape1 {
  position: absolute;
  top: 200px;
  left: 15%;
  animation: mymove 10s infinite;
}

.banner .banner_shape2 {
  position: absolute;
  top: 100px;
  right: 10%;
  animation: mymove 5s infinite;
}

.banner .banner_shape3 {
  position: absolute;
  top: 400px;
  right: 20%;
  animation: mymove 3s infinite;
}

.banner .banner_shape4 {
  position: absolute;
  bottom: 400px;
  left: 20%;
  animation: mymove 6s infinite;
}

.custom-btn {
  /*margin-top:50px;*/
  color: #17556d;
  background-color: #fff;
  font-size: 16px;
  padding: 12px 40px;
  border-radius: 25px;
  font-weight: 500;
  position: relative;
  border: 1px solid #17556d;
  text-transform: uppercase;
  margin-top: 20px;
}

.custom-btn:hover {
  color: #17556d;
}

@keyframes pulse-blue-medium-sm {
  0% {
      box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.4);
  }

  100%,
  30% {
      box-shadow: 0 0 0 20px transparent;
  }
}

@keyframes pulse-blue-small-sm {
  0% {
      box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7);
  }

  100%,
  30% {
      box-shadow: 0 0 0 12px transparent;
  }
}

.custom-btn:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 42px;
  animation: pulse-blue-medium-sm 5s ease-in-out infinite;
}

.custom-btn:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 42px;
  animation-delay: 1s;
  animation: pulse-blue-small-sm 3s ease-in-out infinite;
}

/* list sec css start*/
.list-view {
  margin: 200px 0;
}

.banner-images {
  margin: 100px 0 0px 0;
  max-width: 100%;
  padding: 0 50px;
  padding-bottom: 80px;
}

.banner-images img {
  height: 180px;
  object-fit: contain;
z-index:1;
border-radius:50%;
}

.banner-images a div {
  position:relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

/*.banner-images a div:after{
content:'';
border-radius: 50%;
  height: 150px;
  width: 150px;
   background: #fff ;
   position: absolute;
   animation:moving_position_animatin 6s infinite linear;
}*/

.screen2  a div:after {
  animation-delay: 3s;
}


.screen3  a div:after{
animation-delay: 1s;
}

.screen4  a div:after{
animation-delay: 2s;
}

.banner-images h3 {
  text-transform: capitalize;
  font-size: 20px;
  line-height: 34px;
  font-weight: 500;
  color: #fff;
  margin-top: 20px;
}

@keyframes moving_position_animatin {
  0% {
      transform: translateY(0);
  }

  50% {
      transform: translateY(-30px);
  }

  100% {
      transform: translateY(0);
  }
}

.moving_position_animatin {
  animation: moving_position_animatin 6s infinite linear;
}

.screen2 img {
  animation-delay: 3s;
}

.screen3 img {
  animation-delay: 1s;
}

.screen4 img {
  animation-delay: 2s;
}

.screen5 img {
  animation-delay: 1s;
}

/* media query*/
@media (max-width: 767px) {
  .banner {
      padding-top: 100px;
  }

  .banner h1 {
      font-size: 32px;
      line-height: 36px;
      letter-spacing: -1.5px;
  }

  .banner p {
      padding: 10px 20px;
  }

.home {
  height: auto !important;
      overflow: visible;
}


  .banner-images {
      padding: 0;
      margin: 50px 0 0px 0;
  }

  .banner-images img {
      height: 120px;
      object-fit: contain;
  }


  .banner-images h3 {
      margin-top: 0px;
      margin-bottom: 20px;
  }

  .banner-images .col-lg-3 {
      width: 50%;
  }

  .banner .banner_shape4 {
      display: none;
  }

.banner-images a div:after{
  height: 100px;
  width: 100px;
}
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner h1 {
      font-size: 40px;
  }

.banner-images a div:after{
    height: 120px;
  width: 120px;
}
  .banner-images img {
      height: 120px;
      object-fit: contain;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1600px) {
  .banner {
      padding-top: 120px;
  }

  .banner h1 {
      font-size: 40px;
  }

  .banner-images {
      margin: 80px 0 0px 0;
      padding-bottom: 50px;
  }
}

`;

export const InnerPageContainer = styled.div`
/* left-right sec css start*/
.left-right {
    height: 100vh;
    display: flex;
    align-items: center;
    overflow: hidden;
}

.left-right h2 {
    font-size: 40px;
    font-weight: 700;
    color: #000;
    text-transform: capitalize;
    margin-bottom: 25px;
}

.left-right h2 span {
    color: #17556d;
}

.about_img::before {
    content: "";
    position: absolute;
    left: 38%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 500px;
    height: 500px;
    /* background-color: #f6f4fe;*/
    background-color: rgb(23 85 109 / 40%);
    border-radius: 100%;
    z-index: -1;
}

.frame_img {
    position: absolute;
    top: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    left: 35px;
    display: flex;
    right: 0;
    align-items: center;
    justify-content: start;
}

.frame_img img {
    height: 600px;
    object-fit: contain;
}

.circle::before {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 500px;
    height: 500px;
    /* background-color: #f6f4fe;*/
    background-color: rgb(23 85 109 / 20%);
    border-radius: 100%;
    z-index: -1;
}

.about_img .screen_img {
    margin-left: -180px;
    margin-top: 110px;
    margin-right: 50px;
}

@keyframes moving_object {
    0% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(-30px);
    }

    100% {
        transform: translateY(0);
    }
}

.moving_animation {
    animation: moving_object 6s infinite linear;
}

.about_img .screen_img img {
    animation-delay: 3s;
    width: 700px;
    object-fit: contain;
}

@keyframes moving_position_animatin {
    0% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(-30px);
    }

    100% {
        transform: translateY(0);
    }
}

.moving_position_animatin {
    position: relative;
    animation: moving_position_animatin 6s infinite linear;
}

.about_img {
    display: flex;
    align-items: center;
    position: relative;
}

.list {
    margin: 25px 0;
}

.list li {
    font-size: 16px;
    font-weight: 400;
    line-height: 27.2px;
}

.set-slider {
    position: absolute;
    right: 0;
    top: 80%;
    transform: translate(0%, -40%);
    width: 300px;
}

.set-slider .slick-prev {
    left: 5px;
    z-index: 1;
}

.set-slider .slick-next {
    right: 5px;
}

.set-slider .slick-prev:before {
    content: '←';
    font-family: 'FontAwesome';
	font-size:16px;
}

.set-slider .slick-next:before {
    content:"→";
    font-family: 'FontAwesome';
	font-size:16px;
}




.set-slider .slick-prev,.set-slider .slick-prev:hover,
.set-slider .slick-next,.set-slider .slick-next:hover,
.set-slider .slick-prev:focus,.set-slider .slick-next:focus{
	background: #fff;
	width: 30px;
	height: 30px;
	border-radius: 50%;
	color: #000;
	display: flex !important;
	align-items: center;
	justify-content: center;
	z-index:11;
	padding:0;
	margin:0;
}

.set-slider .slick-prev{
	text-indent: -3px;
}

.set-slider .slick-slide img{
	border-radius:10px;
}

.slick-next:before,.slick-prev:before{
color:#000 !important;
}

.set-slider .slick-list {
    padding: 100px 0;
}

/* footer css */
footer {
    padding: 20px 0;
    background: #17556d;
    position: absolute;
    width: 100%;
    bottom: 0;
}

footer p {
    color: #fff;
    font-size: 16px;
    font-weight: 400;
    line-height: 27.2px;
    letter-spacing: 0.2px;
}

.content {
    padding-left: 50px;
}

.custom-btn {
    color: #fff;
    background-color: #17556d;
    font-size: 16px;
    padding: 12px 40px;
    border-radius: 25px;
    font-weight: 500;
    position: relative;
    border: 1px solid #17556d;
    text-transform: uppercase;
}

.custom-btn:hover {
    color: #fff;
}

/* bg animation*/
.circles{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
	z-index:-1;
}

.circles li{
    position: absolute;
    display: block;
    list-style: none;
    width: 20px;
    height: 20px;
    background: rgb(23 85 109 / 30%);
    animation: animate 25s linear infinite;
    bottom: -150px;
    
}

.circles li:nth-child(1){
    left: 25%;
    width: 80px;
    height: 80px;
    animation-delay: 0s;
}


.circles li:nth-child(2){
    left: 10%;
    width: 20px;
    height: 20px;
    animation-delay: 2s;
    animation-duration: 12s;
}

.circles li:nth-child(3){
    left: 70%;
    width: 20px;
    height: 20px;
    animation-delay: 4s;
}

.circles li:nth-child(4){
    left: 40%;
    width: 60px;
    height: 60px;
    animation-delay: 0s;
    animation-duration: 18s;
}

.circles li:nth-child(5){
    left: 65%;
    width: 20px;
    height: 20px;
    animation-delay: 0s;
}

.circles li:nth-child(6){
    left: 75%;
    width: 110px;
    height: 110px;
    animation-delay: 3s;
}

.circles li:nth-child(7){
    left: 35%;
    width: 150px;
    height: 150px;
    animation-delay: 7s;
}

.circles li:nth-child(8){
    left: 50%;
    width: 25px;
    height: 25px;
    animation-delay: 15s;
    animation-duration: 45s;
}

.circles li:nth-child(9){
    left: 20%;
    width: 15px;
    height: 15px;
    animation-delay: 2s;
    animation-duration: 35s;
}

.circles li:nth-child(10){
    left: 85%;
    width: 150px;
    height: 150px;
    animation-delay: 0s;
    animation-duration: 11s;
}



@keyframes animate {

    0%{
        transform: translateY(0) rotate(0deg);
        opacity: 1;
        border-radius: 0;
    }

    100%{
        transform: translateY(-1000px) rotate(720deg);
        opacity: 0;
        border-radius: 50%;
    }

}

/* media query*/
@media (max-width: 767px) {
    .about_img::before {
        width: 350px;
        height: 350px;
        left: 50%;
    }

    .left-right h2 {
        font-size: 24px;
    }

    .about_img .screen_img {
        margin-left: -170px;
        margin-right: 20px;
    }

    .content {
        padding-left: 15px;
    }

    .circle {
        height: 500px;
        margin-top: 150px;
    }

    .circle::before {
        width: 380px;
        height: 380px;
    }

    .frame_img {
        left: 0 !important;
    }
	
	.set-slider{
		width:250px;
		    right: 10px !important;
			top:40% !important;
	}
	
	  .left-right{
		  padding-top:0 !important;
	  }
	  
	  .frame_img img{
		  height:500px;
	  }
}

@media (max-width: 991px) {
  
    .left-right {
        height: auto;
        display: block;
        padding-top: 150px;
    }
	
	.frame_img{
		left:80px;
	}

    .set-slider {
        right: 40px;
		top:70% !important;
    }
	
    footer {
        position: unset;
        margin-top: 50px;
    }

    /* 
  body .left-right:nth-of-type(even) .row {
        flex-direction: column-reverse;
    }


	body .left-right {
        margin: 50px 0 0 0;
    }

    body .left-right:nth-of-type(1) {
        margin-top: 80px;
    }*/
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .about_img .screen_img {
        margin-left: -200px;
        margin-right: 150px;
    }

    .left-right h2 {
        font-size: 30px;
    }

    .circle {
        height: 500px;
        margin-bottom: 50px;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1600px) {
    .about_img::before {
        left: 46%;
        width: 450px;
        height: 450px;
    }
}


`
  