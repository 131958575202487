import * as actionTypes from "../constants/cartConstants";

const CART_INITIAL_STATE = {
  cartItems: [],
};

export const cartReducer = (state = CART_INITIAL_STATE, action) => {
  const item = action.payload;

  switch (action.type) {
    case actionTypes.ADD_TO_CART:

      return {
        ...state,
        cartItems: [item],
        };
      /* const existItem = state.cartItems.find(
        (x) => x.product === item.product && x.variationId === item.variationId
      );

      if (existItem) {
        console.log("existItem - qty - " + existItem.qty);

        const newData = state.cartItems.map(function (x, i) {
          if (
            x.product === existItem.product &&
            x.variationId === existItem.variationId
          ) {
            x.qty = x.qty + item.qty;
          }
          return x;
        });

        return {
          ...state,
          cartItems: newData,
        };
      } else {
        return {
          ...state,
          cartItems: [...state.cartItems, item],
        };
      } */

    case actionTypes.REMOVE_FROM_CART:
      const existItem2 = state.cartItems.find(
        (x) => x.product === item.product && x.variationId === item.variationId
      );

      if (existItem2) {
        console.log("existItem2 - qty - " + item.qty);

        const newData2 = state.cartItems.map(function (x, i) {
          if (
            x.product === existItem2.product &&
            x.variationId === existItem2.variationId
          ) {
            x.qty = x.qty - item.qty;
          }

          return x;
        });

        return {
          ...state,
          cartItems: newData2.filter((x) => x.qty > 0),
        };

        // return {
        //   ...state,
        //   cartItems: newData2,
        // };
      } else {
        return {
          ...state,
          cartItems: state.cartItems,
        };
      }

    case actionTypes.REMOVE_ALL_FROM_CART:
      const existItem3 = state.cartItems.find(
        (x) => x.product === item.product && x.variationId === item.variationId
      );

      if (existItem3) {
        const newData3 = state.cartItems.map(function (x, i) {
          if (
            x.product === existItem3.product &&
            x.variationId === existItem3.variationId
          ) {
            x.qty = 0;
          }
          return x;
        });

        return {
          ...state,
          cartItems: newData3.filter((x) => x.qty > 0),
        };
      } else {
        return {
          ...state,
          cartItems: state.cartItems,
        };
      }

    case actionTypes.CART_RESET:
      return {
        ...state,
        cartItems: [],
      };
    // return {
    //   ...state,
    //   cartItems: state.cartItems.filter((x) => (x.product !== item.productId && x.variationId !== item.variationId)),
    // };
    default:
      return state;
  }
};
