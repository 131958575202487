import styled from "styled-components";

import logoImg from "../../images/logo.png";
import blackLineImg from "../../images/black-line.png";

export const AboutContainer = styled.div`

.black-title{
  font-style: normal;
  font-weight: 800;
  font-size: 35px;
  text-transform: uppercase;
  text-align: center;
  color: #1A1A1A;
  background-position: 50% 1.4em;
  background-size: 200px 2px;
  background-repeat: no-repeat;
  background-image:url(${blackLineImg});
}

.logo-bg-wrap {
    position: relative;
  }
  
  .logo-bg-wrap:before {
    content: ' ';
    display: block;
    position: absolute;
    left: 10%;
    top: 0;
    width: 75%;
    height: 100%;
    opacity: 0.05;
    background-image:url(${logoImg});
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }
  
  .logo-bg-content {
    position: relative;
  }

`;