export const SAVE_BASIC_DETAILS = "SAVE_BASIC_DETAILS";
export const SAVE_FULL_DETAILS = "SAVE_FULL_DETAILS";
export const RESET_DETAILS = "RESET_DETAILS";

export const PLACE_ORDER_REQUEST = "PLACE_ORDER_REQUEST";
export const PLACE_ORDER_SUCCESS = "PLACE_ORDER_SUCCESS";
export const PLACE_ORDER_COMPLETED = "PLACE_ORDER_COMPLETED";
export const PLACE_ORDER_FAIL = "PLACE_ORDER_FAIL";
export const PLACE_ORDER_RESET = "PLACE_ORDER_RESET";



export const RAZORPAY_VERIFICATION_REQUEST = "RAZORPAY_VERIFICATION_REQUEST";
export const RAZORPAY_VERIFICATION_SUCCESS = "RAZORPAY_VERIFICATION_SUCCESS";
export const RAZORPAY_VERIFICATION_FAIL = "RAZORPAY_VERIFICATION_FAIL";
