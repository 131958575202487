
import styled from "styled-components";

export const CheckoutContainer = styled.div`

.box{
  background: #FFFFFF;
  box-shadow: 0px 0px 100px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
}


.box .title {
  color: #000000;
  text-transform: uppercase;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
}

.box .product-name{
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 24px;
    color: #1A1A1A;
}

.box .product-price{
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #17556D;
}

.order-total .amount {
  font-size: 22px;
  font-weight: 600;
}

.line{
  color: #17556D;
  display: block;
  height: 2px;
  border: 0;
  border-top: 1px solid grey;
  margin: 1em 0;
  padding: 0;
}


.checkout {
    margin-top: 30px;
    border-radius: 30px;
    background-color: #ffffff;
    /* box-shadow: 15px 15px 30px 0px rgb(0 0 0 / 7%), -15px -15px 30px 0px rgb(255 255 255 / 80%); */
    padding: 30px;
    min-height: 404px;
  }

  .checkout-right{
    margin-top: 30px;
    border-radius: 30px;
    background-color: #ffffff;
    /* box-shadow: 15px 15px 30px 0px rgb(0 0 0 / 7%), -15px -15px 30px 0px rgb(255 255 255 / 80%); */
    padding: 30px;
    min-height: 404px;
  }

  .checkout__total {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
  
  .checkout__total p {
    font-size: 14px;
    color: #373737;
    margin-bottom: 10px;
  }
  
  .checkout__total span {
    font-size: 24px;
    color: #373737;
    line-height: 100%;
    font-family: 'Spartan', sans-serif;
    font-weight: 600;
  }

  .cursor-pointer {
    cursor: pointer;
}
`;