import styled from "styled-components";

export const FooterContainer = styled.div`
.footer-main{
    background: #FFFFFF;
    box-shadow: 0px 0px 100px rgba(0, 0, 0, 0.06);
    padding: 50px 0;
}

.footer-main .block ul.social-icon li a{
    background: #17556D;
    color:#17556D
}

.footer-logo{
    max-width: 150px;
}

.footer-main .block-2 ul li a {
    color: #1A1A1A;
}

.footer-main .block-2 ul li a:hover {
    color: #17556D;
}



`;