import axios from 'axios';
import store from '../redux/store';

const axiosInstanceFileUpload = axios.create({
    baseURL: `/api/`,
});

axiosInstanceFileUpload.interceptors.request.use((config) => {

    const state = store.getState();
    const token = state.auth.auth.token;

    console.log("axiosInstanceFileUpload authData - " + JSON.stringify(state.auth));

    console.log("axiosInstanceFileUpload auth token - " + token);

    config.headers = {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
        'Access-Control-Allow-Origin': '*',
    };
    config.proxy = {
        host: 'localhost',
        port: 5000
    }

    console.log("axiosInstanceFileUpload config ==> " + JSON.stringify(config));
    return config;
});

export default axiosInstanceFileUpload;
