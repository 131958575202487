import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import Resizer from "react-image-file-resizer";
import { Button, Form, Row, Col, Spinner, Card, OverlayTrigger, Tooltip, Tab, Tabs, Modal } from "react-bootstrap";

//import { Nav,Tab,Tabs,TabContainer,TabContent,TabPane} from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";

import Breadcrumb from "react-bootstrap/Breadcrumb";

// Actions
import { getProfileDetails, saveProfile, resetSaveProfile, } from "../../redux/actions/cardActions";

import { toast } from "react-toastify";
import { HexColorPicker } from "react-colorful";

// Actions
import { useAuth } from "../../providers/use-auth.js";

import profileTypeList from "../../utils/profile-type";

import themeList from "../../utils/themes";

import { EditProfileContainer } from "./style";

import axiosInstance from "../../services/AxiosInstance";
import axiosInstanceFileUpload from "../../services/AxiosInstanceFileUpload";

//import cardBG from "../../images/profile-bg-1.png";
//import cardBG2 from "../../images/profile-bg-2.png";

const cardBG = "https://dashboard.tap1ce.me/media/profile-bg-1.png";
const cardBG2 = "https://dashboard.tap1ce.me/media/profile-bg-2.png";

const EditProfilePage = ({ match, history }) => {
  const [profileImage, setProfileImage] = useState("");
  const [companyImage, setCompanyImage] = useState("");
  const [primaryColor, setPrimaryColor] = useState("#20647A");
  const [accentColor, setAccentColor] = useState("#d3a583");

  const [profileName, setProfileName] = useState("");

  const [activeTab, setActiveTab] = useState("personal");

  const [prefix, setPrefix] = useState("");

  const [showQR, setShowQR] = useState(false);
  const [theme, setTheme] = useState("simple");

  const [name, setName] = useState("");
  const [designation, setDesignation] = useState("");
  const [company, setCompany] = useState("");

  const [link, setLink] = useState("");
  const [profileType, setProfileType] = useState(0);

  const [errors, setErrors] = useState({});

  const [userProfile, setUserProfile] = useState(null);

  const [portfolio, setPortfolio] = useState([
    {
      type: "photo",
      photo: "https://i.ytimg.com/vi/8YDd8Wgjj28/maxresdefault.jpg",
      caption: "",
      actionTitle: "Visit Store",
      link: "https://comket.in",
    },
  ]);

  const [portfolioModal, setPortfolioModal] = useState(false);
  const [activePortfolio, setActivePortfolio] = useState(null);
  const [isEditPortfolio, setIsEditPortfolio] = useState(false);

  const [portfolioPhoto, setportfolioPhoto] = useState("");
  const [portfolioType, setportfolioType] = useState("photo");
  const [portfolioActionTitle, setPortfolioActionTitle] = useState("");
  const [portfolioLink, setPortfolioLink] = useState("");
  const [portfolioCaption, setPortfolioCaption] = useState("");

  //list
  const [websites, setWebsites] = useState([]);
  const [emails, setEmails] = useState([]);
  const [phones, setPhones] = useState([]);
  const [addresses, setAddresses] = useState([]);

  const [skype, setSkype] = useState("");
  const [hangouts, setHangouts] = useState("");
  const [twitter, setTwitter] = useState("");
  const [linkedin, setLinkedin] = useState("");
  const [linkedinCompany, setLinkedinCompany] = useState("");
  const [facebook, setFacebook] = useState("");
  const [instagram, setInstagram] = useState("");

  const [bio, setBio] = useState("");
  const [service, setService] = useState("");
  const [showMore, setShowMore] = useState(false);
  const [progress, setProgress] = useState(false);
  const [cardId, setCardId] = useState("");

  const [regCompanyName, setRegCompanyName] = useState("");
  const [regCompanyAddress, setRegCompanyAddress] = useState("");

  const [gstNumber, setGstNumber] = useState("");
  const [panNumber, setpanNumber] = useState("");
  const [whatsappNumber, setWhatsappNumber] = useState("");

  const [acType, setAcType] = useState("Current");
  const [acName, setAcName] = useState("");
  const [acNumber, setAcNumber] = useState("");
  const [bankName, setBankName] = useState("");
  const [branchName, setBranchName] = useState("");
  const [ifscCode, setIfscCode] = useState("");

  const [showBankDetails, setShowBankDetails] = useState(false);
  const [showBusinessDetails, setShowBusinessDetails] = useState(true);

  //website type
  const websiteType = ["Home", "Work"];
  const emailType = ["Home", "Work", "Other"];
  const phoneType = ["Mobile", "Home", "Work", "Other"];
  const addressType = ["Home", "Work"];

  //1Link

  const [username, setUsername] = useState("");
  const [oneLinkTitle, setOneLinkTitle] = useState("");
  const [oneLinkAbout, setOneLinkAbout] = useState("");
  const [oneLinks, setOneLinks] = useState([]);

  const [uploadedFile, setUploadedFile] = useState(null);

  const dispatch = useDispatch();

  const profileDetails = useSelector((state) => state.getProfileDetails);
  const { profileLoading, profileError, profile } = profileDetails;

  const saveProfileSelector = useSelector((state) => state.saveProfile);
  const { saveProfileLoading, isProfileSaved, saveProfileError } =
    saveProfileSelector;

  const userSelector = useSelector((state) => state.auth);
  const { auth } = userSelector;

  const fireAuth = useAuth();
  const { state } = useLocation();

  console.log("state 1 - " + state);
  console.log("state 1 - " + JSON.stringify(state));

  useEffect(() => {
    console.log("state - " + state);
    console.log("state - " + JSON.stringify(state));
    if (state) {
      console.log("cardId - " + state.cardId);
      if (state.cardId) {
        setCardId(state.cardId);
      }
    }
  }, []);

  useEffect(() => {
    if (auth && fireAuth.user) {
      setProgress(false);
      //dispatch(getUser());
      dispatch(getProfileDetails(match.params.id));
      loadProfileDetails();
    } else {
      //return history.push("/sign-in");
      setProgress(true);
    }
  }, [fireAuth.user]);

  const onChangeProfileType = (type) => {
    setProfileType(type);
  };

  useEffect(() => {
    if (profile) {
      console.log("profile - " + JSON.stringify(profile));

      // if(profile.namePrefix === "undefined"){
      //   profile.namePrefix ="";
      // }

      const profileTypeIndex = profileTypeList.findIndex(
        (x) => x.type === profile.type
      );

      if (profileTypeIndex != -1) {
        setProfileType(profileTypeIndex);
        // if (profileTypeIndex == 6) {
        //   setProfileType(0);
        // } else {
        //   setProfileType(profileTypeIndex);
        // }
      }

      setProfileName(profile.profileName ? profile.profileName : "");

      setPortfolio(profile.portfolio ?? []);

      setTheme(profile.theme ?? "simple");

      setName(profile.name ? profile.name : "");
      setProfileImage(profile.profile ? profile.profile : "");
      setCompanyImage(profile.companyLogo ? profile.companyLogo : "");
      setPrimaryColor(profile.primaryColor ? profile.primaryColor : "#20647A");
      setAccentColor(profile.accentColor ? profile.accentColor : "#d3a583");

      if (profile.namePrefix) {
        setPrefix(profile.namePrefix != "undefined" ? profile.namePrefix : "");
      }

      setCompany(profile.company ? profile.company : "");

      setBio(profile.bio ? profile.bio : "");

      setDesignation(profile.designation ? profile.designation : "");

      //add website
      const websiteData = profile.websites.map(function (x, i) {
        return { type: x.type, link: x.link };
      });
      setWebsites(websiteData);

      //add phone
      const phoneData = profile.phones.map(function (x, i) {
        return { type: x.type, phone: x.phone };
      });
      setPhones(phoneData);

      //add emails
      const emailData = profile.emails.map(function (x, i) {
        return x;
      });
      setEmails(emailData);

      //add address
      const addressData = profile.addresses.map(function (x, i) {
        return x;
      });
      setAddresses(addressData);

      const twitterData = profile.socialAccounts.find(
        (x) => x.type === "twitter"
      );
      if (twitterData) {
        setTwitter(twitterData.link);
      }

      const skypeData = profile.socialAccounts.find((x) => x.type === "skype");
      if (skypeData) {
        setSkype(skypeData.link);
      }

      const hangoutsData = profile.socialAccounts.find(
        (x) => x.type === "hangouts"
      );
      if (hangoutsData) {
        setHangouts(hangoutsData.link);
      }

      const linkedinData2 = profile.socialAccounts.find(
        (x) => x.type === "linkedinCompnay"
      );
      if (linkedinData2) {
        setLinkedinCompany(linkedinData2.link);
      }

      const linkedinData = profile.socialAccounts.find(
        (x) => x.type === "Linkedin" || x.type === "linkedin"
      );
      if (linkedinData) {
        setLinkedin(linkedinData.link);
      }

      const facebookData = profile.socialAccounts.find(
        (x) => x.type === "facebook"
      );
      if (facebookData) {
        setFacebook(facebookData.link);
      }

      const instagramData = profile.socialAccounts.find(
        (x) => x.type === "instagram"
      );
      if (instagramData) {
        setInstagram(instagramData.link);
      }

      if (profile.type === "virtual" || profile.type === "business") {
        setWhatsappNumber(profile.whatsappNumber ?? "");
        setGstNumber(profile.gstNumber ?? "");
        setpanNumber(profile.panNumber ?? "");

        setRegCompanyName(profile.regCompanyName ?? "");
        setRegCompanyAddress(profile.regCompanyAddress ?? "");
        setShowBankDetails(profile.showBankDetails ?? false);

        setShowBusinessDetails(profile.showBusinessDetails ?? false);

        if (profile.bank) {
          setAcType(profile.bank.acType ?? "");
          setAcName(profile.bank.acName ?? "");
          setAcNumber(profile.bank.acNumber ?? "");
          setIfscCode(profile.bank.ifscCode ?? "");
          setBankName(profile.bank.bankName ?? "");
          setBranchName(profile.bank.branchName ?? "");
        }
      } else if (profile.type === "1link") {
        console.log("is 1lik");

        setProfileImage(profile.oneLink?.photo);
        setCompanyImage(profile.oneLink?.companyLogo);
        setPrimaryColor(profile.oneLink?.primaryColor);
        setAccentColor(profile.oneLink?.accentColor);
        setUsername(profile.oneLink?.username);
        setOneLinkTitle(profile.oneLink?.title);
        setOneLinkAbout(profile.oneLink?.about);

        const websiteData2 = profile.oneLink.otherLinks.map(function (x, i) {
          return { title: x.title, link: x.link };
        });
        setOneLinks(websiteData2);

        const twitterData2 = profile.oneLink.socialLinks.find(
          (x) => x.type === "twitter"
        );
        if (twitterData2) {
          setTwitter(twitterData2.link);
        }

        const skypeData2 = profile.oneLink.socialLinks.find(
          (x) => x.type === "skype"
        );
        if (skypeData2) {
          setSkype(skypeData2.link);
        }

        const hangoutsData2 = profile.oneLink.socialLinks.find(
          (x) => x.type === "hangouts"
        );
        if (hangoutsData2) {
          setHangouts(hangoutsData2.link);
        }

        const linkedinData4 = profile.oneLink.socialLinks.find(
          (x) => x.type === "linkedinCompnay"
        );
        if (linkedinData4) {
          setLinkedinCompany(linkedinData4.link);
        }

        const linkedinData3 = profile.oneLink.socialLinks.find(
          (x) => x.type === "Linkedin" || x.type === "linkedin"
        );
        if (linkedinData3) {
          setLinkedin(linkedinData3.link);
        }

        const facebookData2 = profile.oneLink.socialLinks.find(
          (x) => x.type === "facebook"
        );
        if (facebookData2) {
          setFacebook(facebookData2.link);
        }

        const instagramData2 = profile.oneLink.socialLinks.find(
          (x) => x.type === "instagram"
        );

        if (instagramData2) {
          setInstagram(instagramData2.link);
        }

        const waData = profile.oneLink.socialLinks.find(
          (x) => x.type === "whatsapp"
        );
        if (waData) {
          setWhatsappNumber(waData.link);
        }
      } else {
        setLink(profile.link ? profile.link : "");
      }

      // } else {
      //   setLink(profile.link ? profile.link : "");
      // }
    }
  }, [profile]);

  useEffect(() => {
    if (saveProfileLoading) {
      setProgress(true);
    }

    if (saveProfileError) {
      setProgress(false);
      toast.error(saveProfileError);
    }

    if (isProfileSaved) {
      setProgress(false);
      toast.success("profile saved!");
      dispatch(resetSaveProfile());

      //history.push({pathname:'/cards/'+match.params.id,});

      console.log("redirect to cardId" + cardId);

      if (cardId != "") {
        console.log("redirect to /cards/" + cardId);
        history.push("/cards/" + cardId);
      } else {
        console.log("redirect to cards");
        history.push({ pathname: `/my-account`, state: { menu: "cards" } });
      }
    }
  }, [saveProfileLoading, isProfileSaved, saveProfileError]);

  function fullName() {
    var name = profile.name;

    if (!(profile.namePrefix === "undefined") && profile.namePrefix) {
      name = profile.namePrefix + " " + name;
    }
    return name;
  }

  const onShowMore = async (e) => {
    e.preventDefault();
    setShowMore(!showMore);
  };

  const onAddWebsite = async (e) => {
    e.preventDefault();

    if (websites.length < 5) {
      setWebsites((websites) => [
        ...websites,
        { type: websiteType[0], link: "" },
      ]);
    } else {
      toast.warn("You can't add more then 5 websites");
    }
  };

  const onRemoveWebsite = async (value) => {
    const newArray = websites.filter(function (element) {
      return element != value;
    });
    setWebsites(newArray);
  };

  const onChangeWebsiteType = async (i, type) => {
    websites[i].type = type;
    setWebsites((websites) => [...websites]);
  };

  const onChangeWebsite = async (i, link) => {
    websites[i].link = link;
    setWebsites((websites) => [...websites]);
  };

  const loadProfileDetails = async () => {
    const { data } = await axiosInstance.get("auth/profile");

    if (data.user) {
      setUserProfile(data.user);
    }
  };

  //1 Link
  const onAdd1Link = async (e) => {
    e.preventDefault();

    if (oneLinks.length < 10) {
      setOneLinks((oneLinks) => [...oneLinks, { title: "", link: "" }]);
    } else {
      toast.warn("You can't add more then 10 links");
    }
  };

  const onRemove1Link = async (value) => {
    const newArray = oneLinks.filter(function (element) {
      return element != value;
    });
    setOneLinks(newArray);
  };

  const onChange1LinkTitle = async (i, title) => {
    oneLinks[i].title = title;
    setOneLinks((websites) => [...websites]);
  };

  const onChange1LinkUrl = async (i, link) => {
    oneLinks[i].link = link;
    setOneLinks((websites) => [...websites]);
  };

  const onAddEmail = async (e) => {
    e.preventDefault();

    if (emails.length < 5) {
      setEmails((emails) => [...emails, { type: emailType[0], email: "" }]);
    } else {
      toast.warn("You can't add more then 5 email");
    }
  };

  const onRemoveEmail = async (value) => {
    const newArray = emails.filter(function (element) {
      return element != value;
    });
    setEmails(newArray);
  };

  const onChangeEmailType = async (i, type) => {
    emails[i].type = type;
    setEmails((emails) => [...emails]);
  };

  const onChangeEmail = async (i, email) => {
    emails[i].email = email;
    setWebsites((emails) => [...emails]);
  };

  const onAddPhone = async (e) => {
    e.preventDefault();

    if (phones.length < 5) {
      setPhones((phones) => [...phones, { type: phoneType[0], phone: "" }]);
    } else {
      toast.warn("You can't add more then 5 phone number");
    }
  };

  const onRemovePhone = async (value) => {
    const newArray = phones.filter(function (element) {
      return element != value;
    });
    setPhones(newArray);
  };

  const onChangePhoneType = async (i, type) => {
    phones[i].type = type;
    setPhones((phones) => [...phones]);
  };

  const onChangePhone = async (i, phone) => {
    phones[i].phone = phone;
    setPhones((phones) => [...phones]);
  };

  //address
  const onAddAddress = async (e) => {
    e.preventDefault();

    if (addresses.length < 5) {
      setAddresses((addresses) => [
        ...addresses,
        {
          type: addressType[0],
          street: "",
          city: "",
          state: "",
          country: "",
          zip: "",
        },
      ]);
    } else {
      toast.warn("You can't add more then 5 email");
    }
  };

  const onRemoveAddress = async (value) => {
    const newArray = addresses.filter(function (element) {
      return element != value;
    });
    setAddresses(newArray);
  };

  const onChangeAddressType = async (i, type) => {
    addresses[i].type = type;
    setAddresses((addresses) => [...addresses]);
  };

  const onChangeStreet = async (i, value) => {
    addresses[i].street = value;
    setAddresses((addresses) => [...addresses]);
  };
  const onChangeCity = async (i, value) => {
    addresses[i].city = value;
    setAddresses((addresses) => [...addresses]);
  };
  const onChangeState = async (i, value) => {
    addresses[i].state = value;
    setAddresses((addresses) => [...addresses]);
  };
  const onChangeZip = async (i, value) => {
    addresses[i].zip = value;
    setAddresses((addresses) => [...addresses]);
  };
  const onChangeCountry = async (i, value) => {
    addresses[i].country = value;
    setAddresses((addresses) => [...addresses]);
  };

  const fileChangedHandler = async (event) => {
    var fileInput = false;
    if (event.target.files[0]) {
      fileInput = true;
    }
    if (fileInput) {
      try {
        Resizer.imageFileResizer(
          event.target.files[0],
          300,
          300,
          "PNG",
          100,
          0,
          (uri) => {
            console.log(uri);
            //this.setState({ newImage: uri });
            setProfileImage(uri);
          },
          "base64",
          200,
          200
        );
      } catch (err) {
        console.log(err);
        setProfileImage("");
      }
    }
  };

  const fileClickHandler = (event) => {
    const { target = {} } = event || {};
    target.value = "";
  };

  const fileCompanyChangedHandler = async (event) => {
    var fileInput = false;
    if (event.target.files[0]) {
      fileInput = true;
    }
    if (fileInput) {
      try {
        Resizer.imageFileResizer(
          event.target.files[0],
          300,
          300,
          "PNG",
          100,
          0,
          (uri) => {
            console.log(uri);
            //this.setState({ newImage: uri });
            setCompanyImage(uri);
          },
          "base64",
          200,
          200
        );
      } catch (err) {
        console.log(err);
        setCompanyImage("");
      }
    }
  };

  const fileCompanyClickHandler = (event) => {
    const { target = {} } = event || {};
    target.value = "";
  };

  const upload = (e) => {
    e.preventDefault();
    document.getElementById("selectFile").click();
  };

  const uploadCompany = (e) => {
    e.preventDefault();
    document.getElementById("selectCompanyFile").click();
  };

  const onSaveProfile = async (e) => {
    e.preventDefault();
    setProgress(true);

    if (profile) {
      const data = {
        //update
        profile_name: profileName,
        typeId: profileType,
        type: profileTypeList[profileType].type,
        link: link,

        id: profile.id,
        name_prefix: prefix,
        name: name,
        company: company,
        designation: designation,
        websites: websites,
        emails: emails,
        phones: phones,
        addresses: addresses,
        bio: bio,

        profile: profileImage,
        companyLogo: companyImage,
        primaryColor: primaryColor,
        accentColor: accentColor,

        reg_company_name: regCompanyName,
        reg_company_address: regCompanyAddress,
        gst_number: gstNumber,
        pan_number: panNumber,
        whatsapp_number: whatsappNumber,
        showBankDetails: showBankDetails,
        showBusinessDetails: showBusinessDetails,

        theme: theme,
        portfolio: portfolio,

        bank: {
          acType: acType,
          acName: acName,
          acNumber: acNumber,
          ifscCode: ifscCode,
          bankName: bankName,
          branchName: branchName,
        },

        //oneLink:{
        username: username,
        title: oneLinkTitle,
        about: oneLinkAbout,
        other_links: oneLinks,
        //}

        social_accounts: [
          {
            type: "skype",
            link: skype,
          },
          {
            type: "hangouts",
            link: hangouts,
          },
          {
            type: "twitter",
            link: twitter,
          },
          {
            type: "linkedin",
            link: linkedin,
          },
          {
            type: "linkedinCompnay",
            link: linkedinCompany,
          },
          {
            type: "facebook",
            link: facebook,
          },
          {
            type: "instagram",
            link: instagram,
          },
          {
            type: "whatsapp",
            link: whatsappNumber,
          },
        ],
      };

      return dispatch(saveProfile(data));
    } else {
      setProgress(false);
      return toast.warn("Profile record not found");
    }
  };

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        1024,
        1024,
        "PNG",
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "file"
      );
    });

  const uploadFile = async (file) => {
    return new Promise(async (resolve) => {
      const formData = new FormData();
      console.log(file);

      const resizedFile = await resizeFile(file);

      console.log(resizedFile);

      formData.append("image", resizedFile, file.name);
      formData.append("type", "image");

      const { data } = await axiosInstanceFileUpload.post("app/upload-image", formData);

      //console.log("image upload response - "+data);
      console.log("image upload response - " + JSON.stringify(data));

      if (data.status) {
        resolve(data.imageUrl);
      } else {
        resolve();
      }
    });
  };

  const fileChangedHandler2 = async (event) => {
    var fileInput = false;
    if (event.target.files[0]) {
      fileInput = true;
    }
    if (fileInput) {
      try {
        var file = event.target.files[0];
        setUploadedFile(file);

        // Resizer.imageFileResizer(
        //   event.target.files[0],
        //   300,
        //   300,
        //   "PNG",
        //   100,
        //   0,
        //   (uri) => {
        //     console.log(uri);
        //     //this.setState({ newImage: uri });
        //     setportfolioPhoto(uri);
        //   },
        //   "base64",
        //   200,
        //   200
        // );
      } catch (err) {
        console.log(err);
        setUploadedFile(null);
      }
    }
  };

  const fileClickHandler2 = (event) => {
    const { target = {} } = event || {};
    target.value = "";
  };

  const upload2 = (e) => {
    e.preventDefault();
    document.getElementById("selectFile2").click();
  };

  const onSavePortfolio = async (e) => {
    e.preventDefault();
    let error = false;
    const errorObj = { ...errors };

    if (portfolioType === "photo" && uploadedFile === null) {
      errorObj.photo = "Portfolio Photo is required!";
      error = true;
    }

    if (portfolioType === "youtube" && portfolioLink === "") {
      errorObj.link = "Youtube Link is required!";
      error = true;
    }

    setErrors(errorObj);
    if (error) {
      console.log("error on save");
      return;
    }
    console.log("no error on save");
    //save data

    var imageUrl = "";

    if (portfolioType === "photo") {
      const image = await uploadFile(uploadedFile);
      if (image) {
        imageUrl = image;
      }
    }

    if (isEditPortfolio) {
      var dummy = [...portfolio];
      const index = portfolio.findIndex((x) => x === activePortfolio);

      if (index != -1) {
        dummy[index] = {
          type: portfolioType,
          photo: imageUrl,
          caption: portfolioCaption,
          actionTitle: portfolioActionTitle,
          link: portfolioLink,
        };
        setPortfolio(dummy);
      }
    } else {
      setPortfolio([
        ...portfolio,
        {
          type: portfolioType,
          photo: imageUrl,
          caption: portfolioCaption,
          actionTitle: portfolioActionTitle,
          link: portfolioLink,
        },
      ]);
    }

    setIsEditPortfolio(false);
    setActivePortfolio(null);
    setPortfolioModal(false);
    setUploadedFile(null);
  };

  function formBlock() {
    if (!profile) {
      return (
        <div className="container text-center">
          <h5>Loading Profile...</h5>
        </div>
      );
    }

    if (profileType == 0) {
      return (
        <Tabs
          defaultActiveKey="personal"
          id="uncontrolled-tab-example"
          className="mb-3"
        >
          <Tab eventKey="personal" title="Personal Details">
            <div className="title-text py-2">
              <h5 className="h5 font-weight-bold">Personal Information</h5>
            </div>

            <Row className="mb-3">
              <Col lg={theme === 'custom' ? 3 : 4} className="text-center">
                <label>User Picture</label><br />
                <img
                  onClick={upload}
                  className="img img-fluid avatar"
                  src={
                    profileImage != ""
                      ? profileImage
                      : "https://dashboard.tap1ce.me/media/avatar.png"
                  }
                ></img>
                <input
                  id="selectFile"
                  type="file"
                  hidden
                  onChange={fileChangedHandler}
                  onClick={fileClickHandler}
                />
              </Col>
              {
                theme === 'custom' && (
                  <Col lg={3} className="text-center">
                    <label>Company Logo</label><br />
                    <img
                      onClick={uploadCompany}
                      className="img img-fluid avatar c-logo"
                      src={
                        companyImage != ""
                          ? companyImage
                          : "https://dashboard.tap1ce.me/media/avatar.png"
                      }
                    ></img>
                    <input
                      id="selectCompanyFile"
                      type="file"
                      hidden
                      onChange={fileCompanyChangedHandler}
                      onClick={fileCompanyClickHandler}
                    />
                  </Col>
                )
              }

              <Form.Group as={Col} lg={theme === 'custom' ? 6 : 8}>
                <Form.Label>
                  Profile Name{" "}
                  <OverlayTrigger
                    placement="top"
                    delay={{ show: 250, hide: 400 }}
                    overlay={
                      <Tooltip>
                        This is visible only to you for labeling your profiles
                        and ease of switching in profiles.{" "}
                      </Tooltip>
                    }
                  >
                    <i
                      className="fa fa-info-circle text-primary"
                      aria-hidden="true"
                    ></i>
                  </OverlayTrigger>
                </Form.Label>
                <Form.Control
                  className="main"
                  type="text"
                  placeholder="Profile Name"
                  value={profileName}
                  onChange={(e) => setProfileName(e.target.value)}
                />
              </Form.Group>
            </Row>

            <Row className="mb-3">
              <Form.Group as={Col} lg={4} controlId="formName">
                <Form.Label>
                  Prefix{" "}
                  <OverlayTrigger
                    placement="top"
                    delay={{ show: 250, hide: 400 }}
                    overlay={<Tooltip>Mr / Ms / Mrs.. </Tooltip>}
                  >
                    <i
                      className="fa fa-info-circle text-primary"
                      aria-hidden="true"
                    ></i>
                  </OverlayTrigger>
                </Form.Label>
                <Form.Control
                  className="main"
                  type="text"
                  placeholder="Enter Prefix"
                  value={prefix}
                  onChange={(e) => setPrefix(e.target.value)}
                />
              </Form.Group>

              <Form.Group as={Col} lg={8} controlId="formPhone">
                <Form.Label className="required-field">
                  Name{" "}
                  <OverlayTrigger
                    placement="top"
                    delay={{ show: 250, hide: 400 }}
                    overlay={
                      <Tooltip>Name you want contact to be saved as</Tooltip>
                    }
                  >
                    <i
                      className="fa fa-info-circle text-primary"
                      aria-hidden="true"
                    ></i>
                  </OverlayTrigger>
                </Form.Label>
                <Form.Control
                  className="main"
                  placeholder="Enter Full Name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                />
              </Form.Group>
            </Row>

            <Row className="mb-3">
              <Form.Group as={Col} lg={6} controlId="formCompany">
                <Form.Label
                  className={profileType == 0 ? "required-field" : ""}
                >
                  Company / Organization{" "}
                  <OverlayTrigger
                    placement="top"
                    delay={{ show: 250, hide: 400 }}
                    overlay={<Tooltip>Name of your organization</Tooltip>}
                  >
                    <i
                      className="fa fa-info-circle text-primary"
                      aria-hidden="true"
                    ></i>
                  </OverlayTrigger>
                </Form.Label>
                <Form.Control
                  className="main"
                  type="text"
                  placeholder="Enter Company Name"
                  value={company}
                  onChange={(e) => setCompany(e.target.value)}
                  required={profileType == 0}
                />
              </Form.Group>

              <Form.Group as={Col} lg={6} controlId="formDesignation">
                <Form.Label
                  className={profileType == 0 ? "required-field" : ""}
                >
                  Designation / Role / Title{" "}
                  <OverlayTrigger
                    placement="top"
                    delay={{ show: 250, hide: 400 }}
                    overlay={<Tooltip>Your role in the organization</Tooltip>}
                  >
                    <i
                      className="fa fa-info-circle text-primary"
                      aria-hidden="true"
                    ></i>
                  </OverlayTrigger>
                </Form.Label>
                <Form.Control
                  className="main"
                  type="text"
                  placeholder="Enter Designation"
                  value={designation}
                  onChange={(e) => setDesignation(e.target.value)}
                  required={profileType == 0}
                />
              </Form.Group>
            </Row>

            <Row className="mb-3">
              <Form.Group as={Col} lg={12}>
                <Form.Label>
                  Bio{" "}
                  <OverlayTrigger
                    placement="top"
                    delay={{ show: 250, hide: 400 }}
                    overlay={
                      <Tooltip>
                        Any additional text details you would like to add
                      </Tooltip>
                    }
                  >
                    <i
                      className="fa fa-info-circle text-primary"
                      aria-hidden="true"
                    ></i>
                  </OverlayTrigger>
                </Form.Label>
                <Form.Control
                  className="main"
                  type="text"
                  as="textarea"
                  rows={3}
                  placeholder="Enter Bio"
                  value={bio}
                  onChange={(e) => setBio(e.target.value)}
                />
              </Form.Group>
            </Row>
          </Tab>

          <Tab eventKey="contact" title="Contact Details">
            <div className="title-text py-2">
              <h5 className="h5 font-weight-bold">Contact Information</h5>
            </div>

            <Row className="mb-3">
              <Col>
                <Form.Label>
                  Website{" "}
                  <OverlayTrigger
                    placement="top"
                    delay={{ show: 250, hide: 400 }}
                    overlay={
                      <Tooltip>
                        Your website url copied from the web browser. Including
                        "https//:"
                      </Tooltip>
                    }
                  >
                    <i
                      className="fa fa-info-circle text-primary"
                      aria-hidden="true"
                    ></i>
                  </OverlayTrigger>
                </Form.Label>
              </Col>

              <Col className="text-right">
                <Link onClick={onAddWebsite} className="btn btn-sm btn-blue-2">
                  Add Website
                </Link>
              </Col>
            </Row>

            {websites.map((website, i) => (
              <Row className="mb-3" key={i}>
                <Form.Group as={Col} lg={4}>
                  <Form.Control
                    as="select"
                    className="main"
                    value={websites[i].type}
                    onChange={(e) => {
                      onChangeWebsiteType(i, e.target.value);
                    }}
                  >
                    {websiteType.map((type, i) => (
                      <option key={i} value={type}>
                        {type}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>

                <Form.Group as={Col} lg={6}>
                  <Form.Control
                    className="main"
                    type="text"
                    placeholder="Website"
                    value={website.link}
                    onChange={(e) => onChangeWebsite(i, e.target.value)}
                    required
                  />
                </Form.Group>

                <Col lg={2} className="text-center">
                  <Link
                    onClick={(e) => onRemoveWebsite(website)}
                    className="btn btn-sm btn-danger"
                  >
                    Remove
                  </Link>
                </Col>
              </Row>
            ))}

            <Row className="mb-3">
              <Col>
                <Form.Label>Email</Form.Label>
              </Col>

              <Col className="text-right">
                <Link onClick={onAddEmail} className="btn btn-sm btn-blue-2">
                  Add Email
                </Link>
              </Col>
            </Row>

            {emails.map((email, i) => (
              <Row className="mb-3" key={i}>
                <Form.Group as={Col} lg={4}>
                  <Form.Control
                    as="select"
                    className="main"
                    value={emails[i].type}
                    onChange={(e) => {
                      onChangeEmailType(i, e.target.value);
                    }}
                  >
                    {emailType.map((type, i) => (
                      <option key={i} value={type}>
                        {type}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>

                <Form.Group as={Col} lg={6}>
                  <Form.Control
                    className="main"
                    type="text"
                    placeholder="email"
                    value={email.email}
                    onChange={(e) => onChangeEmail(i, e.target.value)}
                    required
                  />
                </Form.Group>

                <Col lg={2} className="text-center">
                  <Link
                    onClick={(e) => onRemoveEmail(email)}
                    className="btn btn-sm btn-danger"
                  >
                    Remove
                  </Link>
                </Col>
              </Row>
            ))}

            <Row className="mb-3">
              <Col>
                <Form.Label>Phone</Form.Label>
              </Col>

              <Col className="text-right">
                <Link onClick={onAddPhone} className="btn btn-sm btn-blue-2">
                  Add Phone
                </Link>
              </Col>
            </Row>

            {phones.map((phone, i) => (
              <Row className="mb-3" key={i}>
                <Form.Group as={Col} lg={4}>
                  <Form.Control
                    as="select"
                    className="main"
                    value={phones[i].type}
                    onChange={(e) => {
                      onChangePhoneType(i, e.target.value);
                    }}
                  >
                    {phoneType.map((type, i) => (
                      <option key={i} value={type}>
                        {type}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>

                <Form.Group as={Col} lg={6}>
                  <Form.Control
                    className="main"
                    type="text"
                    placeholder="phone"
                    value={phone.phone}
                    onChange={(e) => onChangePhone(i, e.target.value)}
                    required
                  />
                </Form.Group>

                <Col lg={2} className="text-center">
                  <Link
                    onClick={(e) => onRemovePhone(phone)}
                    className="btn btn-sm btn-danger"
                  >
                    Remove
                  </Link>
                </Col>
              </Row>
            ))}

            <Row className="mb-3">
              <Col>
                <Form.Label>
                  Address{" "}
                  <OverlayTrigger
                    placement="top"
                    delay={{ show: 250, hide: 400 }}
                    overlay={<Tooltip>Your detailed address</Tooltip>}
                  >
                    <i
                      className="fa fa-info-circle text-primary"
                      aria-hidden="true"
                    ></i>
                  </OverlayTrigger>
                </Form.Label>
              </Col>

              <Col className="text-right">
                <Link onClick={onAddAddress} className="btn btn-sm btn-blue-2">
                  Add Address
                </Link>
              </Col>
            </Row>

            {addresses.map((address, i) => (
              <Row className="mb-3" key={i}>
                <Form.Group as={Col} lg={4}>
                  <Form.Control
                    as="select"
                    className="main"
                    value={addresses[i].type}
                    onChange={(e) => {
                      onChangeAddressType(i, e.target.value);
                    }}
                  >
                    {phoneType.map((type, i) => (
                      <option key={i} value={type}>
                        {type}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>

                <Col lg={6}>
                  <Form.Group>
                    <Form.Control
                      className="main"
                      type="text"
                      placeholder="street"
                      value={address.street}
                      onChange={(e) => onChangeStreet(i, e.target.value)}
                      required
                    />
                  </Form.Group>

                  <Form.Group>
                    <Form.Control
                      className="main"
                      type="text"
                      placeholder="city"
                      value={address.city}
                      onChange={(e) => onChangeCity(i, e.target.value)}
                      required
                    />
                  </Form.Group>

                  <Form.Group>
                    <Form.Control
                      className="main"
                      type="text"
                      placeholder="state"
                      value={address.state}
                      onChange={(e) => onChangeState(i, e.target.value)}
                      required
                    />
                  </Form.Group>

                  <Form.Group>
                    <Form.Control
                      className="main"
                      type="text"
                      placeholder="country"
                      value={address.country}
                      onChange={(e) => onChangeCountry(i, e.target.value)}
                      required
                    />
                  </Form.Group>

                  <Form.Group>
                    <Form.Control
                      className="main"
                      type="text"
                      placeholder="zip"
                      value={address.zip}
                      onChange={(e) => onChangeZip(i, e.target.value)}
                      required
                    />
                  </Form.Group>
                </Col>

                <Col lg={2} className="text-center">
                  <Link
                    onClick={(e) => onRemoveAddress(address)}
                    className="btn btn-sm btn-danger"
                  >
                    Remove
                  </Link>
                </Col>
              </Row>
            ))}
          </Tab>

          <Tab eventKey="social" title="Social Media">
            <div className="title-text py-2">
              <h5 className="h5 font-weight-bold">Social Media Information</h5>
            </div>

            <Row className="mb-3">
              <Form.Group as={Col} lg={6}>
                <Form.Label>
                  Skype{" "}
                  <OverlayTrigger
                    placement="top"
                    delay={{ show: 250, hide: 400 }}
                    overlay={
                      <Tooltip>Your Skype user name. "@username"</Tooltip>
                    }
                  >
                    <i
                      className="fa fa-info-circle text-primary"
                      aria-hidden="true"
                    ></i>
                  </OverlayTrigger>
                </Form.Label>
                <Form.Control
                  className="main"
                  type="text"
                  placeholder="@Skype"
                  value={skype}
                  onChange={(e) => setSkype(e.target.value)}
                />
              </Form.Group>

              <Form.Group as={Col} lg={6}>
                <Form.Label>
                  Hangouts{" "}
                  <OverlayTrigger
                    placement="top"
                    delay={{ show: 250, hide: 400 }}
                    overlay={
                      <Tooltip>Your Hangouts user name. "username"</Tooltip>
                    }
                  >
                    <i
                      className="fa fa-info-circle text-primary"
                      aria-hidden="true"
                    ></i>
                  </OverlayTrigger>
                </Form.Label>
                <Form.Control
                  className="main"
                  placeholder="@Hangouts"
                  value={hangouts}
                  onChange={(e) => setHangouts(e.target.value)}
                />
              </Form.Group>
            </Row>

            <Row className="mb-3">
              <Form.Group as={Col} lg={6}>
                <Form.Label>
                  Twitter{" "}
                  <OverlayTrigger
                    placement="top"
                    delay={{ show: 250, hide: 400 }}
                    overlay={
                      <Tooltip>Your Twitter user name. "@username"</Tooltip>
                    }
                  >
                    <i
                      className="fa fa-info-circle text-primary"
                      aria-hidden="true"
                    ></i>
                  </OverlayTrigger>
                </Form.Label>
                <Form.Control
                  className="main"
                  type="text"
                  placeholder="@Twitter"
                  value={twitter}
                  onChange={(e) => setTwitter(e.target.value)}
                />
              </Form.Group>

              <Form.Group as={Col} lg={6}>
                <Form.Label>
                  LinkedIn{" "}
                  <OverlayTrigger
                    placement="top"
                    delay={{ show: 250, hide: 400 }}
                    overlay={
                      <Tooltip>Your Linkedin user name. "@username"</Tooltip>
                    }
                  >
                    <i
                      className="fa fa-info-circle text-primary"
                      aria-hidden="true"
                    ></i>
                  </OverlayTrigger>
                </Form.Label>
                <Form.Control
                  className="main"
                  placeholder="@LinkedIn"
                  value={linkedin}
                  onChange={(e) => setLinkedin(e.target.value)}
                />
              </Form.Group>
            </Row>

            <Row className="mb-3">
              <Form.Group as={Col} lg={6}>
                <Form.Label>
                  Facebook{" "}
                  <OverlayTrigger
                    placement="top"
                    delay={{ show: 250, hide: 400 }}
                    overlay={
                      <Tooltip>Your Facebook user name. "@username"</Tooltip>
                    }
                  >
                    <i
                      className="fa fa-info-circle text-primary"
                      aria-hidden="true"
                    ></i>
                  </OverlayTrigger>
                </Form.Label>
                <Form.Control
                  className="main"
                  type="text"
                  placeholder="@Facebook"
                  value={facebook}
                  onChange={(e) => setFacebook(e.target.value)}
                />
              </Form.Group>

              <Form.Group as={Col} lg={6}>
                <Form.Label>
                  Instagram{" "}
                  <OverlayTrigger
                    placement="top"
                    delay={{ show: 250, hide: 400 }}
                    overlay={
                      <Tooltip>Your Instagram user name. "@username"</Tooltip>
                    }
                  >
                    <i
                      className="fa fa-info-circle text-primary"
                      aria-hidden="true"
                    ></i>
                  </OverlayTrigger>
                </Form.Label>
                <Form.Control
                  className="main"
                  placeholder="@Instagram"
                  value={instagram}
                  onChange={(e) => setInstagram(e.target.value)}
                />
              </Form.Group>
            </Row>

            <Row className="mb-3">
              <Form.Group as={Col} lg={6}>
                <Form.Label>
                  Whatsapp Number{" "}
                  <OverlayTrigger
                    placement="top"
                    delay={{ show: 250, hide: 400 }}
                    overlay={
                      <Tooltip>
                        Your WhatsApp number. "+91 Your-10-digit-number "
                      </Tooltip>
                    }
                  >
                    <i
                      className="fa fa-info-circle text-primary"
                      aria-hidden="true"
                    ></i>
                  </OverlayTrigger>
                </Form.Label>
                <Form.Control
                  className="main"
                  placeholder="+91"
                  value={whatsappNumber}
                  onChange={(e) => setWhatsappNumber(e.target.value)}
                />
              </Form.Group>

              <Form.Group as={Col} lg={6}>
                <Form.Label>
                  LinkedIn Company{" "}
                  <OverlayTrigger
                    placement="top"
                    delay={{ show: 250, hide: 400 }}
                    overlay={
                      <Tooltip>
                        Your Linkedin Company user name. "@username"
                      </Tooltip>
                    }
                  >
                    <i
                      className="fa fa-info-circle text-primary"
                      aria-hidden="true"
                    ></i>
                  </OverlayTrigger>
                </Form.Label>
                <Form.Control
                  className="main"
                  placeholder="@LinkedIn Company"
                  value={linkedinCompany}
                  onChange={(e) => setLinkedinCompany(e.target.value)}
                />
              </Form.Group>
            </Row>
          </Tab>

          <Tab eventKey="business" title="Business Details">
            <Row className="mt-2 title-text">
              <Col>
                <h5 className="h5 font-weight-bold">Business Details</h5>
              </Col>

              <Col></Col>

              <Col className="text-right">
                <Form.Control
                  as="select"
                  className="main"
                  value={showBusinessDetails ? "Show" : "Hide"}
                  onChange={(e) => {
                    setShowBusinessDetails(e.target.value == "Show");
                  }}
                >
                  <option value="Show">Show</option>

                  <option value="Hide">Hide</option>
                </Form.Control>
              </Col>
            </Row>

            <div className="row">
              <Form.Group as={Col} lg={12}>
                <Form.Label>Registered Company Name</Form.Label>
                <Form.Control
                  className="main"
                  placeholder="Enter Company Name"
                  value={regCompanyName}
                  onChange={(e) => setRegCompanyName(e.target.value)}
                />
              </Form.Group>

              <Form.Group as={Col} lg={12}>
                <Form.Label>Registered Company Address</Form.Label>
                <Form.Control
                  className="main"
                  placeholder="Enter Company Address"
                  value={regCompanyAddress}
                  onChange={(e) => setRegCompanyAddress(e.target.value)}
                />
              </Form.Group>

              <Form.Group as={Col} lg={12}>
                <Form.Label>GST Number</Form.Label>
                <Form.Control
                  className="main"
                  placeholder="Enter GST Number"
                  value={gstNumber}
                  onChange={(e) => setGstNumber(e.target.value)}
                />
              </Form.Group>

              {/*  <Form.Group as={Col} lg={6}>
                  <Form.Label>PAN Number</Form.Label>
                  <Form.Control
                    className="main"
                    placeholder="Enter PAN Number"
                    value={panNumber}
                    onChange={(e) => setpanNumber(e.target.value)}
                  />
                </Form.Group> */}
            </div>

            <Row className="mt-2 title-text">
              <Col>
                <h5 className="h5 font-weight-bold">Bank Details</h5>
              </Col>

              <Col></Col>

              <Col className="text-right">
                <Form.Control
                  as="select"
                  className="main"
                  value={showBankDetails ? "Show" : "Hide"}
                  onChange={(e) => {
                    setShowBankDetails(e.target.value == "Show");
                  }}
                >
                  <option value="Show">Show</option>

                  <option value="Hide">Hide</option>
                </Form.Control>
              </Col>
            </Row>

            <div className="row">
              <Form.Group as={Col} lg={12}>
                <Form.Label>A/C Type</Form.Label>
                <Form.Control
                  as="select"
                  className="main"
                  value={acType}
                  onChange={(e) => {
                    setAcType(e.target.value);
                  }}
                >
                  <option value="Current">Current</option>

                  <option value="Savings">Savings</option>
                </Form.Control>
              </Form.Group>

              <Form.Group as={Col} lg={12}>
                <Form.Label>A/C Name</Form.Label>
                <Form.Control
                  className="main"
                  placeholder="Enter A/C Name"
                  value={acName}
                  onChange={(e) => setAcName(e.target.value)}
                />
              </Form.Group>

              <Form.Group as={Col} lg={12}>
                <Form.Label>A/C Number</Form.Label>
                <Form.Control
                  className="main"
                  placeholder="Enter A/C Number"
                  value={acNumber}
                  onChange={(e) => setAcNumber(e.target.value)}
                />
              </Form.Group>

              <Form.Group as={Col} lg={12}>
                <Form.Label>IFSC Code</Form.Label>
                <Form.Control
                  className="main"
                  placeholder="Enter IFSC Code"
                  value={ifscCode}
                  onChange={(e) => setIfscCode(e.target.value)}
                />
              </Form.Group>

              <Form.Group as={Col} lg={12}>
                <Form.Label>Bank Name</Form.Label>
                <Form.Control
                  className="main"
                  placeholder="Enter Bank Name"
                  value={bankName}
                  onChange={(e) => setBankName(e.target.value)}
                />
              </Form.Group>

              <Form.Group as={Col} lg={12}>
                <Form.Label>Branch Name</Form.Label>
                <Form.Control
                  className="main"
                  placeholder="Enter Branch Name"
                  value={branchName}
                  onChange={(e) => setBranchName(e.target.value)}
                />
              </Form.Group>
            </div>
          </Tab>

          <Tab eventKey="portfolio" title="Portfolio">
            <Row className="mt-2 title-text">
              <Col lg={6}>
                <h5 className="h5 font-weight-bold">Portfolio</h5>
              </Col>

              <Col lg={6} className="text-right">
                <Button
                  onClick={(e) => {
                    setActivePortfolio(null);
                    setIsEditPortfolio(false);

                    setportfolioPhoto("");
                    setportfolioType("photo");
                    setPortfolioActionTitle("");
                    setPortfolioCaption("");
                    setPortfolioLink("");

                    setPortfolioModal(true);
                  }}
                  className="btn btn-sm"
                >
                  Add Portfolio
                </Button>
              </Col>
            </Row>

            <div className="row mt-2">
              {portfolio.map((p, i) => {
                return (
                  <div className="col-lg-4 mb-5" key={"portfolio-" + i}>
                    <div>
                      <Card>
                        <Card.Img
                          variant="top"
                          src={
                            p.type == "photo"
                              ? p.photo
                              : "https://comket.in/tap1ce/youtube.png"
                          }
                        />

                        <Card.Footer className="text-right">
                          <Button
                            className="btn btn-sm btn-primary mr-2"
                            onClick={(e) => {
                              setActivePortfolio(p);
                              setIsEditPortfolio(true);
                              setportfolioType(p.type ?? "photo");
                              setportfolioPhoto(p.photo ?? "");
                              setPortfolioActionTitle(p.actionTitle ?? "");
                              setPortfolioCaption(p.caption ?? "");
                              setPortfolioLink(p.link ?? "");

                              setPortfolioModal(true);
                              setUploadedFile(null);
                            }}
                          >
                            Edit
                          </Button>

                          <Button
                            className="btn btn-sm btn-danger btn-delete"
                            onClick={(e) =>
                              setPortfolio(portfolio.filter((x) => x != p))
                            }
                          >
                            <i className="ti-trash"></i>
                          </Button>
                        </Card.Footer>
                      </Card>
                    </div>
                  </div>
                );
              })}
            </div>
          </Tab>
        </Tabs>
      );
    } else if (profileType == 6) {
      return (
        <>
          <div className="title-text py-2">
            <h5 className="h5 font-weight-bold">1 Link Information</h5>
          </div>

          <Row className="mb-3">
            <Col lg={theme === 'custom' ? 3 : 4} className="text-center">
              <label>User Picture</label><br />
              <img
                onClick={upload}
                className="img img-fluid avatar"
                src={
                  profileImage != ""
                    ? profileImage
                    : "https://dashboard.tap1ce.me/media/avatar.png"
                }
              ></img>
              <input
                id="selectFile"
                type="file"
                hidden
                onChange={fileChangedHandler}
                onClick={fileClickHandler}
              />
            </Col>
            {
              theme === 'custom' && (
                <Col lg={3} className="text-center">
                  <label>Company Logo</label><br />
                  <img
                    onClick={uploadCompany}
                    className="img img-fluid avatar c-logo"
                    src={
                      companyImage != ""
                        ? companyImage
                        : "https://dashboard.tap1ce.me/media/avatar.png"
                    }
                  ></img>
                  <input
                    id="selectCompanyFile"
                    type="file"
                    hidden
                    onChange={fileCompanyChangedHandler}
                    onClick={fileCompanyClickHandler}
                  />
                </Col>
              )
            }

            <Form.Group as={Col} lg={theme === 'custom' ? 6 : 8}>
              <div className="input-group mb-4">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <small>https://1link.tap1ce.me/</small>
                  </span>
                </div>

                <input
                  type="text"
                  className="form-control"
                  placeholder="Username"
                  aria-label="Username"
                  aria-describedby="basic-addon2"
                  value={username}
                  //pattern={/^[a-z0-9_\.]+$/}
                  //pattern="/^[a-z0-9_\.]+$/"
                  onChange={(e) => setUsername(e.target.value)}
                />
              </div>

              <Form.Label>
                Title{" "}
                <OverlayTrigger
                  placement="top"
                  delay={{ show: 250, hide: 400 }}
                  overlay={
                    <Tooltip>
                      This is visible only to you for labeling your 1 Link.{" "}
                    </Tooltip>
                  }
                >
                  <i
                    className="fa fa-info-circle text-primary"
                    aria-hidden="true"
                  ></i>
                </OverlayTrigger>
              </Form.Label>
              <Form.Control
                className="main"
                type="text"
                placeholder="Title"
                value={oneLinkTitle}
                onChange={(e) => setOneLinkTitle(e.target.value)}
              />
            </Form.Group>
          </Row>

          <Row className="mb-3">
            <Form.Group as={Col} lg={12}>
              <Form.Label>
                About{" "}
                <OverlayTrigger
                  placement="top"
                  delay={{ show: 250, hide: 400 }}
                  overlay={
                    <Tooltip>
                      Any additional text details you would like to add
                    </Tooltip>
                  }
                >
                  <i
                    className="fa fa-info-circle text-primary"
                    aria-hidden="true"
                  ></i>
                </OverlayTrigger>
              </Form.Label>
              <Form.Control
                className="main"
                type="text"
                as="textarea"
                rows={3}
                placeholder="Enter about"
                value={oneLinkAbout}
                onChange={(e) => setOneLinkAbout(e.target.value)}
              />
            </Form.Group>
          </Row>

          <div className="title-text py-2">
            <h5 className="h5 font-weight-bold">Social Media Information</h5>
          </div>

          <Row className="mb-3">
            <Form.Group as={Col} lg={6}>
              <Form.Label>
                Skype{" "}
                <OverlayTrigger
                  placement="top"
                  delay={{ show: 250, hide: 400 }}
                  overlay={<Tooltip>Your Skype user name. "@username"</Tooltip>}
                >
                  <i
                    className="fa fa-info-circle text-primary"
                    aria-hidden="true"
                  ></i>
                </OverlayTrigger>
              </Form.Label>
              <Form.Control
                className="main"
                type="text"
                placeholder="@Skype"
                value={skype}
                onChange={(e) => setSkype(e.target.value)}
              />
            </Form.Group>

            <Form.Group as={Col} lg={6}>
              <Form.Label>
                Hangouts{" "}
                <OverlayTrigger
                  placement="top"
                  delay={{ show: 250, hide: 400 }}
                  overlay={
                    <Tooltip>Your Hangouts user name. "username"</Tooltip>
                  }
                >
                  <i
                    className="fa fa-info-circle text-primary"
                    aria-hidden="true"
                  ></i>
                </OverlayTrigger>
              </Form.Label>
              <Form.Control
                className="main"
                placeholder="@Hangouts"
                value={hangouts}
                onChange={(e) => setHangouts(e.target.value)}
              />
            </Form.Group>
          </Row>

          <Row className="mb-3">
            <Form.Group as={Col} lg={6}>
              <Form.Label>
                Twitter{" "}
                <OverlayTrigger
                  placement="top"
                  delay={{ show: 250, hide: 400 }}
                  overlay={
                    <Tooltip>Your Twitter user name. "@username"</Tooltip>
                  }
                >
                  <i
                    className="fa fa-info-circle text-primary"
                    aria-hidden="true"
                  ></i>
                </OverlayTrigger>
              </Form.Label>
              <Form.Control
                className="main"
                type="text"
                placeholder="@Twitter"
                value={twitter}
                onChange={(e) => setTwitter(e.target.value)}
              />
            </Form.Group>

            <Form.Group as={Col} lg={6}>
              <Form.Label>
                LinkedIn{" "}
                <OverlayTrigger
                  placement="top"
                  delay={{ show: 250, hide: 400 }}
                  overlay={
                    <Tooltip>Your Linkedin user name. "@username"</Tooltip>
                  }
                >
                  <i
                    className="fa fa-info-circle text-primary"
                    aria-hidden="true"
                  ></i>
                </OverlayTrigger>
              </Form.Label>
              <Form.Control
                className="main"
                placeholder="@LinkedIn"
                value={linkedin}
                onChange={(e) => setLinkedin(e.target.value)}
              />
            </Form.Group>
          </Row>

          <Row className="mb-3">
            <Form.Group as={Col} lg={6}>
              <Form.Label>
                Facebook{" "}
                <OverlayTrigger
                  placement="top"
                  delay={{ show: 250, hide: 400 }}
                  overlay={
                    <Tooltip>Your Facebook user name. "@username"</Tooltip>
                  }
                >
                  <i
                    className="fa fa-info-circle text-primary"
                    aria-hidden="true"
                  ></i>
                </OverlayTrigger>
              </Form.Label>
              <Form.Control
                className="main"
                type="text"
                placeholder="@Facebook"
                value={facebook}
                onChange={(e) => setFacebook(e.target.value)}
              />
            </Form.Group>

            <Form.Group as={Col} lg={6}>
              <Form.Label>
                Instagram{" "}
                <OverlayTrigger
                  placement="top"
                  delay={{ show: 250, hide: 400 }}
                  overlay={
                    <Tooltip>Your Instagram user name. "@username"</Tooltip>
                  }
                >
                  <i
                    className="fa fa-info-circle text-primary"
                    aria-hidden="true"
                  ></i>
                </OverlayTrigger>
              </Form.Label>
              <Form.Control
                className="main"
                placeholder="@Instagram"
                value={instagram}
                onChange={(e) => setInstagram(e.target.value)}
              />
            </Form.Group>
          </Row>

          <Row className="mb-3">
            <Form.Group as={Col} lg={6}>
              <Form.Label>
                Whatsapp Number{" "}
                <OverlayTrigger
                  placement="top"
                  delay={{ show: 250, hide: 400 }}
                  overlay={
                    <Tooltip>
                      Your WhatsApp number. "+91 Your-10-digit-number "
                    </Tooltip>
                  }
                >
                  <i
                    className="fa fa-info-circle text-primary"
                    aria-hidden="true"
                  ></i>
                </OverlayTrigger>
              </Form.Label>
              <Form.Control
                className="main"
                placeholder="+91"
                value={whatsappNumber}
                onChange={(e) => setWhatsappNumber(e.target.value)}
              />
            </Form.Group>

            <Form.Group as={Col} lg={6}>
              <Form.Label>
                LinkedIn Company{" "}
                <OverlayTrigger
                  placement="top"
                  delay={{ show: 250, hide: 400 }}
                  overlay={
                    <Tooltip>
                      Your Linkedin Company user name. "@username"
                    </Tooltip>
                  }
                >
                  <i
                    className="fa fa-info-circle text-primary"
                    aria-hidden="true"
                  ></i>
                </OverlayTrigger>
              </Form.Label>
              <Form.Control
                className="main"
                placeholder="@LinkedIn Company"
                value={linkedinCompany}
                onChange={(e) => setLinkedinCompany(e.target.value)}
              />
            </Form.Group>
          </Row>

          <Row className="mb-3">
            <Col>
              <Form.Label>
                Others Links{" "}
                <OverlayTrigger
                  placement="top"
                  delay={{ show: 250, hide: 400 }}
                  overlay={
                    <Tooltip>
                      Your website url copied from the web browser. Including
                      "https//:"
                    </Tooltip>
                  }
                >
                  <i
                    className="fa fa-info-circle text-primary"
                    aria-hidden="true"
                  ></i>
                </OverlayTrigger>
              </Form.Label>
            </Col>

            <Col className="text-right">
              <Link onClick={onAdd1Link} className="btn btn-sm btn-blue-2">
                Add Link
              </Link>
            </Col>
          </Row>

          {oneLinks.map((website, i) => (
            <Row className="mb-3" key={i}>
              <Form.Group as={Col} lg={4}>
                <Form.Control
                  className="main"
                  type="text"
                  placeholder="Link Title"
                  value={website.title}
                  onChange={(e) => onChange1LinkTitle(i, e.target.value)}
                  required
                />
              </Form.Group>

              <Form.Group as={Col} lg={6}>
                <Form.Control
                  className="main"
                  type="text"
                  placeholder="Url"
                  value={website.link}
                  onChange={(e) => onChange1LinkUrl(i, e.target.value)}
                  required
                />
              </Form.Group>

              <Col lg={2} className="text-center">
                <Link
                  onClick={(e) => onRemove1Link(website)}
                  className="btn btn-sm btn-danger"
                >
                  Remove
                </Link>
              </Col>
            </Row>
          ))}
        </>
      );
    } else {
      return (
        <>
          <div className="title-text py-2 mb-4">
            <h5 className="h5 font-weight-bold">
              {profileTypeList[profileType].name}
            </h5>
            <p>Enter the details below to create your card profile.</p>
          </div>

          <Row className="mb-3">
            <Form.Group as={Col} lg={12}>
              <Form.Label>Profile Name</Form.Label>
              <Form.Control
                className="main"
                type="text"
                placeholder="Profile Name"
                value={profileName}
                onChange={(e) => setProfileName(e.target.value)}
              />
            </Form.Group>
          </Row>

          <Row className="mb-3">
            <Form.Group as={Col} lg={12}>
              <Form.Label className="required-field">
                {profileTypeList[profileType].inputType}
              </Form.Label>
              <Form.Control
                className="main"
                type="text"
                placeholder={profileTypeList[profileType].hint}
                value={link}
                onChange={(e) => setLink(e.target.value)}
                required
              />
            </Form.Group>
          </Row>
        </>
      );
    }
  }

  async function downloadQR() {
    const downloadLink = document.createElement("a");
    downloadLink.href = "api/profiles/qr/" + match.params.id;
    downloadLink.download = "api/profiles/qr/" + match.params.id;
    downloadLink.click();
  }

  return (
    <>
      <Modal
        size="lg"
        className="modal fade"
        show={portfolioModal}
        onHide={setPortfolioModal}
      >
        <div className="" role="document">
          <div className="">
            <form onSubmit={onSavePortfolio}>
              <div className="modal-header">
                <h4 className="modal-title fs-20">
                  {isEditPortfolio ? "Edit Portfolio" : "Add Portfolio"}
                </h4>
                <button
                  type="button"
                  className="close"
                  onClick={() => setPortfolioModal(false)}
                >
                  <span>×</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="form-row">
                  <Col lg={4} className="text-center">
                    {portfolioType == "photo" ? (
                      <Card onClick={upload2}>
                        <Card.Img
                          variant="top"
                          src={
                            uploadedFile != null
                              ? URL.createObjectURL(uploadedFile)
                              : "https://comket.in/tap1ce/upload-image.jpg"
                          }
                        />

                        {errors.photo && (
                          <div className="text-danger fs-12">
                            <Card.Footer>{errors.photo}</Card.Footer>
                          </div>
                        )}
                      </Card>
                    ) : (
                      <Card>
                        <Card.Img
                          variant="top"
                          src="https://comket.in/tap1ce/youtube.png"
                        ></Card.Img>
                      </Card>
                    )}

                    <input
                      id="selectFile2"
                      type="file"
                      hidden
                      onChange={fileChangedHandler2}
                      onClick={fileClickHandler2}
                    />
                  </Col>

                  <Col lg={8} className="">
                    <div className="form-group">
                      <label>Type</label>

                      <select
                        className="form-control"
                        id="inputState"
                        value={portfolioType}
                        onChange={(e) => setportfolioType(e.target.value)}
                        defaultValue="photo"
                      >
                        <option value="photo">Photo</option>
                        <option value="youtube">Youtube Video</option>
                      </select>
                    </div>

                    <div className="form-row">
                      {portfolioType == "photo" ? (
                        <div className="form-group col-md-6">
                          <label>Action Title</label>
                          <input
                            type="text"
                            value={portfolioActionTitle}
                            onChange={(e) =>
                              setPortfolioActionTitle(e.target.value)
                            }
                            placeholder="e.g Visit Store"
                            className="form-control"
                          />

                          {errors.title && (
                            <div className="text-danger fs-12">
                              {errors.title}
                            </div>
                          )}
                        </div>
                      ) : (
                        <></>
                      )}
                      <div
                        className={
                          "form-group " +
                          (portfolioType == "photo" ? "col-md-6" : "col-md-12")
                        }
                      >
                        <label>
                          {portfolioType == "youtube"
                            ? "Youtube Video Link"
                            : "Action Link"}
                        </label>
                        <input
                          type="text"
                          value={portfolioLink}
                          onChange={(e) => setPortfolioLink(e.target.value)}
                          placeholder="e.g https://"
                          className="form-control"
                        />

                        {errors.link && (
                          <div className="text-danger fs-12">{errors.link}</div>
                        )}
                      </div>
                    </div>
                  </Col>

                  <Form.Group as={Col} lg={12}>
                    <Form.Label>Description</Form.Label>
                    <Form.Control
                      className="main"
                      type="text"
                      as="textarea"
                      rows={3}
                      placeholder=""
                      value={portfolioCaption}
                      onChange={(e) => setPortfolioCaption(e.target.value)}
                    />
                    {errors.desc && (
                      <div className="text-danger fs-12">{errors.desc}</div>
                    )}
                  </Form.Group>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  onClick={() => setPortfolioModal(false)}
                  className="btn btn-danger"
                >
                  {" "}
                  <i className="flaticon-delete-1"></i> Cancel
                </button>

                <button className="btn btn-primary" type="submit">
                  {isEditPortfolio ? "Save" : "Add"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </Modal>

      <EditProfileContainer>
        <section className="section">
          <div className="container">
            {profileLoading ? (
              <h2>Loading...</h2>
            ) : profileError ? (
              <h2>{profileError}</h2>
            ) : (
              <>
                <div className="page-title-section">
                  <Breadcrumb>
                    <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                    <Breadcrumb.Item href={"/cards/" + cardId}>
                      Card
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active>Edit Profile</Breadcrumb.Item>
                  </Breadcrumb>

                  <div className="row">
                    <div className="col-lg-12">
                      <h3 className="title">Edit Profile</h3>
                    </div>

                    {profileType == 0 ? (
                      <div className="col-lg-4">
                        <Form.Control
                          as="select"
                          className="main"
                          value={theme}
                          onChange={(e) => {
                            setTheme(e.target.value);
                          }}
                        >
                          {themeList.map((x) => {
                            if (userProfile) {
                              return userProfile.theme.includes(x.name) ? (
                                <option value={x.name}>{x.title}</option>
                              ) : (
                                <></>
                              );
                            } else {
                              return <option value={x.name}>{x.title}</option>;
                            }
                          })}
                        </Form.Control>

                        {/*   <div className="theme-switch-wrapper">
                        <label className="theme-switch" for="checkbox">
                          <input
                            type="checkbox"
                            checked={theme == "dark"}
                            id="checkbox"
                            onChange={(e) => {
                              setTheme(theme == "light" ? "dark" : "light");
                            }}
                          />
                          <div className="theme-slider round"></div>
                        </label>
                        <em>
                          {theme == "dark" ? "Dark Mode" : "Light Mode"}
                          {"  "}
                          <OverlayTrigger
                            placement="bottom"
                            delay={{ show: 250, hide: 400 }}
                            overlay={
                              <Tooltip>
                                This theme will shown when you share your
                                contact.
                              </Tooltip>
                            }
                          >
                            <i
                              className="fa fa-info-circle text-primary"
                              aria-hidden="true"
                            ></i>
                          </OverlayTrigger>
                        </em>
                      </div> */}
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>

                <section className="section">
                  <Row>
                    <Col lg={4}>
                      <div>
                        <Card className="card-2">
                          <Card.Img variant="top" src={cardBG} />

                          <div className={"card-taps text-left"}>
                            <h2
                              className={"h6 font-weight-bold mb-0 text-white"}
                            >
                              {profileName}
                            </h2>
                          </div>

                          <div className={"card-content text-left text-white"}>
                            <h2
                              className={"h6 font-weight-bold mb-0 text-white"}
                            >
                              <i
                                className={profileTypeList[profileType].icon}
                              ></i>
                              &nbsp;
                              {profileTypeList[profileType].type === "business"
                                ? name === ""
                                  ? "Your Name"
                                  : prefix + " " + name
                                : profileTypeList[profileType].name}
                            </h2>
                            <p
                              className={
                                "text-small sort-link marquee text-white"
                              }
                            >
                              <span>
                                {profileTypeList[profileType].type ===
                                  "business"
                                  ? company
                                  : link}
                              </span>
                            </p>
                          </div>
                        </Card>

                        <div className="mt-2 text-center">
                          <Button
                            variant=""
                            type="button"
                            className="btn btn-lg btn-primary "
                            onClick={(e) => setShowQR(!showQR)}
                          >
                            {!showQR
                              ? "Show Offline QR-Code"
                              : "Hide Offline QR-Code"}
                          </Button>
                        </div>

                        {showQR ? (
                          <Card className="mt-2">
                            <Card.Body>
                              <Card.Title>Tap To Save</Card.Title>
                              <Card.Text>
                                Scan this QR-Code to share with no internet.
                              </Card.Text>
                            </Card.Body>

                            <Card.Img
                              onClick={(e) => downloadQR()}
                              variant="bottom"
                              src={"/api/profiles/qr/" + match.params.id}
                            ></Card.Img>
                          </Card>
                        ) : (
                          <></>
                        )}

                        {
                          (profileType == 0 || profileType == 6) && theme === 'custom' && (
                            <Form.Group className="text-center mt-10 ">
                              <Form.Label>
                                Primary Color
                                <HexColorPicker color={primaryColor} onChange={setPrimaryColor} />
                              </Form.Label>
                              <Form.Control
                                className="main w-200"
                                type="text"
                                placeholder="Primary Color"
                                value={primaryColor}
                                onChange={(e) => setPrimaryColor(e.target.value)}
                              />
                            </Form.Group>
                          )
                        }

                        {
                          (profileType == 0 || profileType == 6) && theme === 'custom' && (
                            <Form.Group className="text-center mt-10 ">
                              <Form.Label>
                                Accent Color
                                <HexColorPicker color={accentColor} onChange={setAccentColor} />
                              </Form.Label>
                              <Form.Control
                                className="main w-200"
                                type="text"
                                placeholder="Accent Color"
                                value={accentColor}
                                onChange={(e) => setAccentColor(e.target.value)}
                              />
                            </Form.Group>
                          )
                        }
                      </div>
                    </Col>

                    <Col lg={8}>
                      {/* 
                    <Row>
                      {profileTypeList.map((pt, i) => (
                        <Col
                          lg={4}
                          md={3}
                          sm={6}
                          xs={12}
                          key={i}
                          className="text-center"
                        >
                          <label key={i} className="profile-label mb-2">
                            <input
                              type="radio"
                              name="profile"
                              className="profile-type-input"
                              id={i}
                              value={i}
                              checked={profileType == i ? true : false}
                              onChange={(e) => {
                                console.log("card change ", e.target.value);
                                onChangeProfileType(i);
                              }}
                            />

                            <div className="card profile-type p-2 text-left">
                              <i
                                className={profileTypeList[i].icon + " bg-icon"}
                              ></i>

                              <i className={profileTypeList[i].icon}></i>

                              <h2 className="h6 font-weight-bold mt-5 profile-name">
                                {profileTypeList[i].name}
                              </h2>

                              <div className="check-item">
                                <div className=" float-left">
                                  <span className="check-icon"></span>
                                </div>
                              </div>
                            </div>
                          </label>
                        </Col>
                      ))}
                    </Row> */}

                      <Form className="py-2" onSubmit={onSaveProfile}>
                        {formBlock()}

                        <Row>
                          <Col className="col-12 text-center">
                            {progress ? (
                              <Spinner animation="border" variant="primary" />
                            ) : (
                              <Button
                                variant=""
                                type="submit"
                                className="btn btn-lg btn-primary"
                              >
                                Save
                              </Button>
                            )}
                          </Col>
                        </Row>
                      </Form>
                    </Col>
                  </Row>
                </section>
              </>
            )}
          </div>
        </section>
      </EditProfileContainer>
    </>
  );
};

export default EditProfilePage;
