import styled from "styled-components";

export const ProductItemContainer = styled.div`

.card{
  background: #FFFFFF;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.08);
  border-radius: 12px;
  border: 0;
}

.card:hover{
  border: 1px solid #17556D;
  border-radius: 12px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.08);
}

.product-name{
  border: 1px solid rgba(26, 26, 26, 0.3);
  box-sizing: border-box;
  border-radius: 12px;

  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  color: #1A1A1A;

}

.btn-light-blue{
  background-color:#b1d4e0;
}

.card2 {
  margin: 0 auto;
  
}

.card2 {
  border-radius: 8px;
  box-shadow: 3px 3px 8px hsl(0, 0%, 70%);
  overflow: hidden;
}
.card2 .card-img-top {
 
}


/* Slider */

.slick-slide, .slick-slide *{ outline: none !important; }

.slick-dots li.slick-active button:before {
  color:White !important;
}

.slick-dots li button:before {
  color:White !important;
}

.product-slider{
  border-radius: "5px";
}

.product-slider .item{
  padding: "20px";
}

/* Product Content */

.product-content {
  background-color: #ffffff;
}

.product-title {

  font-weight: 700;
  font-size: 36px;

  /* font-family: 'Roboto', sans-serif; */
  
}

.product-category {
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.product-price {

  font-weight: 500;
  font-size: 30px;
  /* margin: 10px 0; 
  font-family: 'Roboto', sans-serif; */
}

.product-description {
  margin: 10px 0;
}

.product-features {
  margin: 10px 0;
}

/* Product Color Selector */

.product-color-selector {
  width: 100%;
  float: left;
  margin: 10px 0;
}

.product-color-label {
  float: left;
  margin-top: 4px;
}

.product-colors {
  float: left;
  padding: 0 0 18px 12px;
  margin-bottom: 0;
}

.product-color {
  height: 30px;
  width: 30px;
  background: #cccccc;
  border-radius: 30px;
  display: inline-block;
  margin-right: 3px;
  -webkit-transition: all .2s ease-in-out;
  -o-transition: all .2s ease-in-out;
  transition: all .2s ease-in-out;
}

.product-color:hover {
  cursor: pointer;
}

.product-color-black {
  background: #c4cacc;
}

.product-color-navy {
  background: #0e1033;
}

.product-color-grey {
  background: #E6E6E9;
}

.product-color_active {
-webkit-box-shadow: 0px 5px 15px -4px rgba(0,23,31,1);
-moz-box-shadow: 0px 5px 15px -4px rgba(0,23,31,1);
box-shadow: 0px 5px 15px -4px rgba(0,23,31,1);
}

/* Product Button */

.product-button {
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 1px;
  background: #FF2414;
  border: none;
  border-radius: 0;
  padding: 0.75rem 1.75rem;
  -webkit-box-shadow: 0px 5px 15px -4px rgba(255,36,20,1);
  -moz-box-shadow: 0px 5px 15px -4px rgba(255,36,20,1);
  box-shadow: 0px 5px 15px -4px rgba(255,36,20,1);
}

.product-button:hover {
  cursor: pointer;
  background: #E52012;
  -webkit-transform: scale(1.025);
  -moz-transform: scale(1.025);
  -ms-transform: scale(1.025);
  transform: scale(1.025);
}

/* Slick Slider */

.slick-dots {
  position: absolute;
  bottom: 15px;
}

.slick-dotted.slick-slider {
  margin-bottom: 0;
}

.slick-list, .slick-track {
  height: 100%;
}


.custom-separator {
  width: 5rem;
  height: 6px;
  border-radius: 1rem;
}
`;