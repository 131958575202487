import styled from "styled-components";

export const SignUpContainer = styled.div`
.signup-title{
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 20px;
    text-transform: uppercase;
    color: #000000;
}

label{
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #000000;
}
`;