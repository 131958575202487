
import styled from "styled-components";

export const SingleProductContainer = styled.div`


.preview-section{
  margin-top:100px;
  margin-bottom:100px;
}

.preview-card-image{
  border-radius: 12px;
}

.slick-slider .slick-slide {
  padding: 0 15px;
}

.card-item{
  padding: 00px;
  border: 2px solid rgba(0,0,0,.125);
  border-radius: .25rem;
}

.active{
  border: 2px solid #17556D;
  border-radius: .25rem;
}

.card-item:hover {
  cursor: pointer;
}

.card-item-input {
  display: none;
}

.check-item{
  width: 20px;
  height: 20px;
  display: inline-block;
  border: solid 2px #e3e3e3;
  border-radius: 50%;
  transition: 200ms linear;
  position: absolute;
  top: 5px;
  left: 5px;
}

.check-icon:before {
  content: "";
  position: absolute;
  inset: 0;
  background-image: url("data:image/svg+xml,%3Csvg width='12' height='9' viewBox='0 0 12 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.93552 4.58423C0.890286 4.53718 0.854262 4.48209 0.829309 4.42179C0.779553 4.28741 0.779553 4.13965 0.829309 4.00527C0.853759 3.94471 0.889842 3.88952 0.93552 3.84283L1.68941 3.12018C1.73378 3.06821 1.7893 3.02692 1.85185 2.99939C1.91206 2.97215 1.97736 2.95796 2.04345 2.95774C2.11507 2.95635 2.18613 2.97056 2.2517 2.99939C2.31652 3.02822 2.3752 3.06922 2.42456 3.12018L4.69872 5.39851L9.58026 0.516971C9.62828 0.466328 9.68554 0.42533 9.74895 0.396182C9.81468 0.367844 9.88563 0.353653 9.95721 0.354531C10.0244 0.354903 10.0907 0.369582 10.1517 0.397592C10.2128 0.425602 10.2672 0.466298 10.3112 0.516971L11.0651 1.25003C11.1108 1.29672 11.1469 1.35191 11.1713 1.41247C11.2211 1.54686 11.2211 1.69461 11.1713 1.82899C11.1464 1.88929 11.1104 1.94439 11.0651 1.99143L5.06525 7.96007C5.02054 8.0122 4.96514 8.0541 4.90281 8.08294C4.76944 8.13802 4.61967 8.13802 4.4863 8.08294C4.42397 8.0541 4.36857 8.0122 4.32386 7.96007L0.93552 4.58423Z' fill='white'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-size: 12px;
  background-position: center center;
  transform: scale(1.6);
  transition: 200ms linear;
  opacity: 0;
}

.card-item-input:checked + .card-input {
   box-shadow: 0 0 2px 2px #6394F8;
}

.card-item-input:checked + .card-item .check-item .float-left .check-icon {
  background: #17556D;
  border-color: #17556D;
  transform: scale(1.2);
}


.card-item-input:checked + .card-item .check-item .float-left .check-icon:before {
  transform: scale(1);
  opacity: 1;
  background-color: #17556D;
  border-color: #17556D;
  border-radius: 50%;
}

.active{

}

.text-uppercase {
  letter-spacing: 0.1em;
}





.card-preview .card-body{
  position: absolute;
}


.card-content{
  display: block;
  position: absolute;
  left: 0%;
  right:0%;
  bottom: 10%;
  top: 0%;
}

.card-content .name{
  text-transform: uppercase;
  font-weight: 500!important;
  font-size: 1.75rem;
}


.card-content .name{
  text-transform: uppercase;
  font-weight: 500!important;
  font-size: 1.75rem;
  letter-spacing: 1.2px;
}

@media only screen and (max-width: 600px) {
  .card-content .name{
    text-transform: uppercase;
    font-weight: 500!important;
    font-size: 1rem;
  }
}


`;