import "./App.css";
//import "slick-carousel/slick/slick.css";
//import "slick-carousel/slick/slick-theme.css";
import { Suspense, useState, React, useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useHistory,
  withRouter,
  useLocation,
} from "react-router-dom";
import { connect, useDispatch } from "react-redux";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "react-phone-number-input/style.css";

// Components
import Header from "./components/header/header";
import Footer from "./components/footer/footer";
import SideDrawer from "./components/SideDrawer";
import Backdrop from "./components/Backdrop";

// Pages
import ComingSoonPage from "./screens/coming-soon/coming-soon";
import HomePage from "./screens/home/home";
import HomePage2 from "./screens/home-2/home";
import HomePage3 from "./screens/home-3/home";
import ProductsPage from "./screens/products/products";
import SingleProductPage from "./screens/single-product/single-product";
import CartPage from "./screens/cart/cart";
import CheckoutPage from "./screens/checkout/checkout";
import MyAccountPage from "./screens/my-account/my-account";
import SignInPage from "./screens/sign-in/sign-in";
import SignUpPage from "./screens/sign-up/sign-up";
import ForgotPasswordPage from "./screens/forgot-password/forgot-password";
import ResetPasswordPage from "./screens/reset-password/reset-password";

import ThankYouPage from "./screens/thank-you/thank-you";
import SingleCardPage from "./screens/single-card/single-card";

import PrivacyPolicyPage from "./screens/privacy-policy/privacy-policy";
import TermsAndConditionsPage from "./screens/terms-and-conditions/terms-and-conditions";

import SupportedPhonesPage from "./screens/supported-phones/supported-phones";

import AddProfilePage from "./screens/add-profile/add-profile";
import EditProfilePage from "./screens/edit-profile/edit-profile";

import ContactUsPage from "./screens/contact-us/contact-us";
import AboutUsPage from "./screens/about-us/about-us";
import HowToUsePage from "./screens/how-to-use/how-to-use";

import FAQPage from "./screens/faq/faq";

import ShippingPolicyPage from "./screens/shipping-policy/shipping-policy";

//firebase auth
import { ProvideAuth, analytics } from "./providers/use-auth.js";

import { Spinner } from "react-bootstrap";

// action
import { checkAutoLogin } from "./redux/actions/authActions";
import { isAuthenticated } from "./redux/selectors/AuthSelectors";
import IndividualPage from "./screens/home/individual";
import BusinessPage from "./screens/home/bussiness";
import CorporatePage from "./screens/home/corporation";
import FreelancerPage from "./screens/home/freelancer";
import Franchise from "./screens/franchise/franchise";

import PhoneVerifyPage from "./screens/phone-verify/phone-verify";

// const AnalyticsComponent = () => {
//   const history = useHistory();
//   useEffect(() => {
//     logCurrentPage(); // log the first page visit
//     history.listen(() => {
//       logCurrentPage();
//     });
//   }, [history]);
//   return <div></div>;
// };

function ScrollToTop() {
  const history = useHistory();
  useEffect(() => {
    const unlisten = history.listen((location, action) => {
      if (action !== "POP") {
        window.scrollTo(0, 0);
      }
    });
    return () => unlisten();
  }, []);
  return null;
}

function App(props) {
  const location = useLocation();
  const [path, setPath] = useState("");
  const [isHomePage, setIsHomePage] = useState(true);

  const logCurrentPage = () => {
    const pagePath = window.location.pathname;
    analytics.setCurrentScreen(pagePath);
    analytics.logEvent("page_view");

    setPath(pagePath);
    console.log("path:", pagePath);
  };

  const history = useHistory();
  useEffect(() => {
    logCurrentPage();
    history.listen(() => {
      logCurrentPage();
    });
  }, [history]);

  useEffect(() => {
    setIsHomePage(
      path == "" ||
        path == "/" ||
        path == "/individual" ||
        path == "/corporation" ||
        path == "/business" ||
        path == "/freelancer"
    );
  }, [path]);

  const dispatch = useDispatch();
  useEffect(() => {
    checkAutoLogin(dispatch, props.history);

    const pagePath = window.location.pathname;
    setPath(pagePath);
  }, [dispatch, props.history]);

  const [sideToggle, setSideToggle] = useState(false);

  const isComingSoon = false;
  //const isComingSoon = !(document.domain === "localhost" || document.domain === "3.109.95.243" || document.domain === "192.168.123.215");

  let routes = (
    <ProvideAuth>
      {
        <Router>
          <ScrollToTop />

          <main className="app">
            <Switch>
              <Route exact path="/" component={MyAccountPage} />
              {/* <Route exact path="/individual" component={IndividualPage} />
                <Route exact path="/business" component={BusinessPage} />
                <Route exact path="/corporation" component={CorporatePage} />
                <Route exact path="/freelancer" component={FreelancerPage} /> */}

              {/* <Route exact path="/" component={HomePage} /> */}
              {/* <Route exact path="/home-2" component={HomePage2} />
                <Route exact path="/home" component={HomePage3} />
                <Route exact path="/coming-soon" component={ComingSoonPage} />
                <Route exact path="/products" component={ProductsPage} />
                <Route
                  exact
                  path="/product/:id"
                  component={SingleProductPage}
                />
                <Route exact path="/cart" component={CartPage} />
                <Route exact path="/checkout" component={CheckoutPage} />
             
                <Route exact path="/franchise" component={Franchise } />
                <Route exact path="/phone-verify" component={PhoneVerifyPage} /> */}

              <Route exact path="/my-account" component={MyAccountPage} />
              <Route exact path="/login" component={SignInPage} />
              <Route exact path="/sign-up" component={SignUpPage} />
              <Route
                exact
                path="/forgot-password"
                component={ForgotPasswordPage}
              />
              {/* <Route exact path="/thank-you/:id" component={ThankYouPage} />
                <Route exact path="/faq" component={FAQPage} /> */}

              <Route
                exact
                path="/forgotpassword"
                component={ForgotPasswordPage}
              />
              <Route
                exact
                path="/passwordreset/:resetToken"
                component={ResetPasswordPage}
              />

              <Route exact path="/cards/:id" component={SingleCardPage} />

              {/* <Route
                  exact
                  path="/privacy-policy"
                  component={PrivacyPolicyPage}
                />
                <Route
                  exact
                  path="/terms-and-conditions"
                  component={TermsAndConditionsPage}
                />

                <Route
                  exact
                  path="/supported-phones"
                  component={SupportedPhonesPage}
                /> */}

              <Route exact path="/add-profile/:id" component={AddProfilePage} />
              <Route
                exact
                path="/edit-profile/:id"
                component={EditProfilePage}
              />
              {/* <Route exact path="/contact-us" component={ContactUsPage} />
                <Route exact path="/about-us" component={AboutUsPage} />
                <Route exact path="/how-to-use" component={HowToUsePage} />
                <Route
                  exact
                  path="/shipping-policy"
                  component={ShippingPolicyPage}
                />

                <Route path="*" component={HomePage} />
                <Route path="/*" component={HomePage} />
                <Route path="/:id" component={HomePage} />
                <Route path="/?fbclid=" component={HomePage} /> */}
            </Switch>
          </main>
        </Router>
      }
    </ProvideAuth>
  );

  return (
    <Suspense
      fallback={
        <div id="preloader">
          <Spinner animation="border" variant="primary" />
        </div>
      }
    >
      {routes}
    </Suspense>
  );
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: isAuthenticated(state),
  };
};

export default withRouter(connect(mapStateToProps)(App));

//export default App;
