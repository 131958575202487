import styled from "styled-components";

import appleImg from "../../images/apple.png";
//import headerCardImg from "../../images/header-card.png";

export const TitleContainer = styled.div`

.section{
  height: 50vh!important;
  position: relative;
  background: #FFFFFF;
  box-shadow: 0px 0px 100px rgba(0, 0, 0, 0.06);
}

.title-wrap{

}

.title-wrap:before {
  content: ' ';
  display: block;
  position: absolute;
  left: 30%;
  width: 50%;
  height: 80%;
  bottom:0;
  background-image:url("https://dashboard.tap1ce.me/media//header-card.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.title-content{
  position: relative;
}

.title-content .title{
  font-style: normal;
  font-weight: 800;
  font-size: 40px;
  line-height: 55px;
  text-align: left;
  text-transform: uppercase;
  color: #1A1A1A;
}

.title-content .title::after{
  content: '';
  height: 2px;
  width: 10%;
  background: #1A1A1A;
  position: absolute;
  left: calc(.5rem);
  bottom: 10px;
}


@media only screen and (max-width: 600px) {
  .p-50 {
    padding: 20px;
  }

  .page-title-section .title{
    font-size: 35px;
    line-height: 40px;
  }

  .page-title-section::after {
    width: 25%;
  }

  .title-wrap:before {
    content: ' ';
    display: block;
    position: absolute;
    left: 10%;
    width: 80%;
    height: 100%;
    bottom: 0px;
    background-position-x: center;
    background-position-y: bottom;
  }
}


`;