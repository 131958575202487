import styled from "styled-components";

export const InvitationItemContainer = styled.div`

.card{
  background: #FFFFFF;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
}


.invite-date{
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
}

.product-image{
  border-radius: 8px;
}

`;