import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import {
  Button,
  Form,
  Row,
  Col,
  Nav,
  Tab,
  Tabs,
  TabContainer,
  TabContent,
  TabPane,
  Spinner,
  Badge,
} from "react-bootstrap";
import { toast } from "react-toastify";

import { signIn, getUser } from "../../redux/actions/authActions";
import { useAuth, firebase } from "../../providers/use-auth";
import {
  getAuth,
  signInWithPhoneNumber,
  RecaptchaVerifier,
} from "firebase/auth";

import { LoginContainer } from "./style";

import PhoneInput from "react-phone-number-input";
import OtpInput from "react-otp-input";

//import loginbg from "../../images/login-bg.png";
//import loginBanner from "../../images/login-banner.png";

const LinkPhoneNumber = ({ onSuccess, onFailed }) => {
  let errorsObj = { phone: "", otp: "" };
  const [errors, setErrors] = useState(errorsObj);
  const [mobileNumber, setMobileNumber] = useState("");
  const [otp, setOtp] = useState("");
  const [final, setfinal] = useState("");
  const [loading, setLoading] = useState(false);
  const [tick, setTick] = useState(60);

  const [showOtp, setShowOtp] = useState(false);

  const isAuthenticated = useSelector((state) => state.isAuthenticated);
  const fireAuth = useAuth();

  if (fireAuth.user && isAuthenticated) {
    startTimer();
  }

  const startTimer = () => {
    setTick(60);
  };

  useEffect(() => {
    const timeCheck =
      showOtp === true
        ? setTimeout(() => {
            setTick(tick - 1);
          }, 1000)
        : "";
    if (tick < 1) {
      clearTimeout(timeCheck);
    }

    // if(showOtp){
    //   startTimer();
    // }
  }, [showOtp, tick]);

  useEffect(() => {
    if (showOtp) {
      startTimer();
    }
  }, [showOtp]);

  function setupReCaptcha() {
    window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
      "recaptcha-container",
      {
        size: "invisible",
        callback: (response) => {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
          // ...
          onSendOtp();
        },
        "expired-callback": () => {
          // Response expired. Ask user to solve reCAPTCHA again.
          // ...
        },
      }
    );

    // window.recaptchaVerifier = new RecaptchaVerifier(
    //   "recaptcha-container",
    //   {
    //     size: "invisible",
    //     callback: (response) => {
    //       // reCAPTCHA solved, allow signInWithPhoneNumber.
    //       onSendOtp();
    //     },
    //   },
    //   auth,
    // );

    window.recaptchaVerifier.render().then((widgetId) => {
      window.recaptchaWidgetId = widgetId;
    });
  }

  // Sent OTP
  const onSendOtp = (e) => {
    e.preventDefault();
    let error = false;
    const errorObj = { ...errorsObj };

    console.log("phoneNumber:", mobileNumber);

    if (mobileNumber === "") {
      errorObj.mobileNumber = "Phone Number is Required";
      error = true;
    }

    //return setShowOtp(true);

    setErrors(errorObj);
    if (error) {
      return;
    }

    setLoading(true);
    if (!window.recaptchaVerifier) {
      setupReCaptcha();
    }

    const appVerifier = window.recaptchaVerifier;

    //const auth = getAuth();

    var provider = new firebase.auth.PhoneAuthProvider();
    provider
      .verifyPhoneNumber(mobileNumber, appVerifier)
      .then(function (verificationId) {
        window.verificationId = verificationId;
        setLoading(false);
        setShowOtp(true);
      })
      .catch((error) => {
        // Error occurred.
        console.log(error);
        setLoading(false);
        toast.error(error.message);
      });

    // signInWithPhoneNumber(auth, "+" + mobileNumber, appVerifier)
    //   .then((confirmationResult) => {
    //     window.confirmationResult = confirmationResult;
    //     console.log(confirmationResult);
    //     setLoading(false);
    //     toast.success("OTP Sent On : " + mobileNumber, {
    //       // position: toast.POSITION.TOP_RIGHT,
    //       autoClose: 10000, // 10 seconds
    //       hideProgressBar: true,
    //     });
    //     setShowOtp(true);
    //   })
    //   .catch((err) => {
    //     setLoading(false);
    //     console.log("error -" + err);
    //     toast.error(err.message);
    //     //grecaptcha.reset(window.recaptchaWidgetId);
    //   });
  };

  // Validate OTP
  const onValidateOtp = async (e) => {
    e.preventDefault();

    let error = false;
    const errorObj = { ...errorsObj };

    if (otp === null || window.confirmationResult === null) {
      errorObj.mobileNumber = "OTP is Required";
      error = true;
    }

    setErrors(errorObj);

    if (error) {
      return;
    }

    //return onSuccess();

    setLoading(true);

    try {
      const phoneCredential = firebase.auth.PhoneAuthProvider.credential(
        window.verificationId,
        otp
      );
      await fireAuth.user.updatePhoneNumber(phoneCredential);
      setLoading(false);
      onSuccess({
        phone: mobileNumber,
      });
    } catch (e) {
      console.log("OTP verify error", e);
      setLoading(false);
      onFailed();
    }

    // window.confirmationResult
    //   .confirm(otp)
    //   .then(async (result) => {
    //     // success
    //     setLoading(false);
    //     if (result.hasOwnProperty("user")) {
    //       console.log(result.user);

    //       const idToken = await result.user.getIdToken(true);
    //       console.log("TOKEN ID : ", idToken);

    //       //  api for login (AUTH LOGIN)
    //       const axios = require("axios");
    //       const FormData = require("form-data");
    //       let data = new FormData();
    //       data.append("id_token", idToken);

    //       let config = {
    //         method: "post",
    //         maxBodyLength: Infinity,
    //         url: "http://127.0.0.1:5000/api/auth/login",
    //         data: data,
    //       };
    //       axios
    //         .request(config)
    //         .then((response) => {
    //           console.log(JSON.stringify(response.data));

    //           const data = response.data;
    //           if (data.status) {
    //             onSuccess();
    //           }
    //         })
    //         .catch((error) => {
    //           console.log(error);
    //         });
    //       return;
    //     }
    //   })
    //   .catch((err) => {
    //     setLoading(false);
    //     toast.error(err);
    //   });
  };

  const onResendOTP = () => {
    if (tick === 0) {
    }
  };

  return (
    <div>
      <div className="content">
        {showOtp ? (
          <>
            <Form onSubmit={onValidateOtp}>
              <Link
                className="text-primary"
                onClick={(e) => {
                  setShowOtp(false);
                }}
              >
                Change Phone Number
              </Link>

              <br />

              <Form.Group className="my-4">
                <Form.Label className="required-field">
                  One Time Password
                </Form.Label>

                <OtpInput
                  containerStyle="mt-2"
                  value={otp}
                  onChange={setOtp}
                  numInputs={6}
                  inputType="form-control"
                  //inputStyle="main"
                  renderSeparator={<div className="px-2"></div>}
                  renderInput={(props) => <input className="main" {...props} />}
                  inputStyle={{
                    width: "2rem",
                  }}
                />
              </Form.Group>

              <div className="text-center">
                {loading ? (
                  <Spinner animation="border" variant="primary" />
                ) : (
                  <Button
                    variant="primary"
                    type="submit"
                    className="btn btn-primary btn-block"
                  >
                    Verify
                  </Button>
                )}
              </div>

              <div className="new-account mt-2 text-center">
                <p className="p">
                  Did't receive OTP?{" "}
                  <b>
                    <Link
                      className={tick > 0 ? "" : "text-primary"}
                      onClick={(e) => onResendOTP()}
                    >
                      Resend OTP{" "}
                      {tick > 0 ? (
                        <Badge bg="primary" className="text-white">
                          {tick}
                        </Badge>
                      ) : (
                        <></>
                      )}
                    </Link>
                  </b>
                </p>
              </div>

              {/* <div className="new-account mt-2 text-center">
                        <p className="p">
                          Don't have an account?{" "}
                          <Link className="text-primary" to="./sign-up">
                            Create Account
                          </Link>
                        </p>
                      </div> */}
            </Form>
          </>
        ) : (
          <Form onSubmit={onSendOtp}>
            <Form.Group className="my-4">
              <Form.Label className="required-field">Phone Number</Form.Label>

              <PhoneInput
                defaultCountry="IN"
                placeholder="Enter phone number"
                className="main mt-2"
                value={mobileNumber}
                required
                onChange={setMobileNumber}
              />
            </Form.Group>

            <div className="text-center">
              {loading ? (
                <Spinner animation="border" variant="primary" />
              ) : (
                <Button
                  variant="primary"
                  type="submit"
                  className="btn btn-primary btn-block"
                >
                  Send OTP
                </Button>
              )}
            </div>

            {/* <div className="new-account mt-2 text-center">
                        <p className="p">
                          Don't have an account?{" "}
                          <Link className="text-primary" to="./sign-up">
                            Create Account
                          </Link>
                        </p>
                      </div> */}
          </Form>
        )}

        
      </div>
    </div>
  );
};

export default LinkPhoneNumber;
