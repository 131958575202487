
/**
  Component
**/

import styled from "styled-components";

export const SingleCardContainer = styled.div`

.card .card-body{
  position: absolute;
}

.card:hover{
  background: rgba(0, 0, 0, 0.02);
  border: 1px solid rgba(0, 0, 0, 0.3);
  box-sizing: border-box;
  border-radius: 8px;
  padding:10px;
}

.btn{
  text-transform:capitalize;
  font-size: .75rem;
  line-height: 1.5;
  border-radius: .2rem;
}

.card-taps{
  position: absolute;
  left:12.5%;
  top:12.5%;
  right:0px;
}

.card-content{
  position: absolute;
  left:5%;
  bottom:0px;
  right:0px;
}

.btn-activated{
  background: rgba(44, 180, 105, 0.1);
  color: #2CB469;
}

.btn-make-active{
  background: #2CB469;
  color: #ffffff;
}

.btn-delete{
  background:rgb(190 32 51 / 10%);
  color: #BE2033;
}


label {
  width: 100%;
}

.card-input-element {
  display: none;
}

.rounded {
  border-radius: 1rem !important;
}

.card-input {
  /* margin: 10px; */
  padding: 00px;
}

.card-input:hover {
  cursor: pointer;
}

.card-input-element:checked + .card-input {
   box-shadow: 0 0 2px 2px #6394F8;
}

.caption-1 figcaption {
  position: absolute;
  bottom: 0;
  right: 0;
}

.caption-2 figcaption {
  width: 80%;
  position: absolute;
  bottom: 1rem;
  left: 10%;
  background: rgba(255, 255, 255, 0.6);
}

.caption-3 figcaption {
  position: absolute;
  bottom: 0;
  right: 0;
  transform: translateY(-50%);
}


.effect-2::before{
  position: absolute;
  content: "";
  z-index: -1;
  bottom: 5px;
  left: 30px;
  right: 30px;
  top: 80%;
  background: #aaa;
  box-shadow: 0 0 25px 17px #aaa;
  border-radius: 100px/10px;
}

.check-icon {
  width: 20px;
  height: 20px;
  display: inline-block;
  border: solid 2px #e3e3e3;
  border-radius: 50%;
  transition: 200ms linear;
  position: relative;
}

.check-icon:before {
  content: "";
  position: absolute;
  inset: 0;
  background-image: url("data:image/svg+xml,%3Csvg width='12' height='9' viewBox='0 0 12 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.93552 4.58423C0.890286 4.53718 0.854262 4.48209 0.829309 4.42179C0.779553 4.28741 0.779553 4.13965 0.829309 4.00527C0.853759 3.94471 0.889842 3.88952 0.93552 3.84283L1.68941 3.12018C1.73378 3.06821 1.7893 3.02692 1.85185 2.99939C1.91206 2.97215 1.97736 2.95796 2.04345 2.95774C2.11507 2.95635 2.18613 2.97056 2.2517 2.99939C2.31652 3.02822 2.3752 3.06922 2.42456 3.12018L4.69872 5.39851L9.58026 0.516971C9.62828 0.466328 9.68554 0.42533 9.74895 0.396182C9.81468 0.367844 9.88563 0.353653 9.95721 0.354531C10.0244 0.354903 10.0907 0.369582 10.1517 0.397592C10.2128 0.425602 10.2672 0.466298 10.3112 0.516971L11.0651 1.25003C11.1108 1.29672 11.1469 1.35191 11.1713 1.41247C11.2211 1.54686 11.2211 1.69461 11.1713 1.82899C11.1464 1.88929 11.1104 1.94439 11.0651 1.99143L5.06525 7.96007C5.02054 8.0122 4.96514 8.0541 4.90281 8.08294C4.76944 8.13802 4.61967 8.13802 4.4863 8.08294C4.42397 8.0541 4.36857 8.0122 4.32386 7.96007L0.93552 4.58423Z' fill='white'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-size: 12px;
  background-position: center center;
  transform: scale(1.6);
  transition: 200ms linear;
  opacity: 0;
}


.action-button{
  position: relative;
  float: right;
}

.delete-profile-button {
 /*  position: relative;
  float: right; */
  color:red;
  margin-right:10px;
}

.delete-profile-button:hover {
  color:red;
}

/* .edit-profile-button {
   position: relative;
   float: right; 
} */

.card-input-element:checked + .row .col .float-left .check-icon {
  background: #faaf43;
  border-color: #faaf43;
  transform: scale(1.2);
}


.card-input-element:checked + .row .col .float-left .check-icon:before {
  transform: scale(1);
  opacity: 1;
}
`;