
//all card data
export const CARDS_REQUEST = "CARDS_REQUEST";
export const CARDS_SUCCESS = "CARDS_SUCCESS";
export const CARDS_FAIL = "CARDS_FAIL";

//single card data
export const CARD_DETAILS_REQUEST = "CARD_DETAILS_REQUEST";
export const CARD_DETAILS_SUCCESS = "CARD_DETAILS_SUCCESS";
export const CARD_DETAILS_FAIL = "CARD_DETAILS_FAIL";

//profile list
export const PROFILES_REQUEST = "PROFILE_REQUEST";
export const PROFILES_SUCCESS = "PROFILE_SUCCESS";
export const PROFILES_FAIL = "PROFILE_FAIL";

//single profile data
export const PROFILE_DETAILS_REQUEST = "PROFILE_DETAILS_REQUEST";
export const PROFILE_DETAILS_SUCCESS = "PROFILE_DETAILS_SUCCESS";
export const PROFILE_DETAILS_FAIL = "PROFILE_DETAILS_FAIL";

//add profile
export const ADD_PROFILE_REQUEST = "ADD_PROFILE_REQUEST";
export const ADD_PROFILE_SUCCESS = "ADD_PROFILE_SUCCESS";
export const ADD_PROFILE_FAIL = "ADD_PROFILE_FAIL";
export const ADD_PROFILE_RESET = "ADD_PROFILE_RESET";

//change active profile
export const ACTIVE_PROFILE_REQUEST = "ACTIVE_PROFILE_REQUEST";
export const ACTIVE_PROFILE_SUCCESS = "ACTIVE_PROFILE_SUCCESS";
export const ACTIVE_PROFILE_FAIL = "ACTIVE_PROFILE_FAIL";
export const ACTIVE_PROFILE_RESET = "ACTIVE_PROFILE_RESET";

//save profile data
export const SAVE_PROFILE_REQUEST = "SAVE_PROFILE_REQUEST";
export const SAVE_PROFILE_SUCCESS = "SAVE_PROFILE_SUCCESS";
export const SAVE_PROFILE_FAIL = "SAVE_PROFILE_FAIL";
export const SAVE_PROFILE_RESET = "SAVE_PROFILE_RESET";

//remove profile
export const REMOVE_PROFILE_REQUEST = "REMOVE_PROFILE_REQUEST";
export const REMOVE_PROFILE_SUCCESS = "REMOVE_PROFILE_SUCCESS";
export const REMOVE_PROFILE_FAIL = "REMOVE_PROFILE_FAIL";

