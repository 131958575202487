import styled from "styled-components";

export const ComingSoonContainer = styled.div`
.comingSoon{
  background-color: #0e1033;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%; 
  height: 100%;
  min-height: 100vh;
  font-family: 'Karla', sans-serif;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  color: #ffffff;
}

header{
  z-index: 1;
}

.navbar-brand {
  margin-right: 0; }
  .navbar-brand img {
    height: 55px; }

.navbar-dark {
  padding-top: 35px;
  padding-bottom: 35px; }
  .navbar-dark .navbar-text {
    color: #ffffff;
    opacity: 1;
    padding: 0 20px;
    border-right: 1px solid #ffffff;
    font-size: 18px;
    font-weight: bold;
    text-align: center; }
    .navbar-dark .navbar-text:last-child {
      border-right: 0; }
  @media (max-width: 767px) {
    .navbar-dark {
      padding-bottom: 0; } }

.page-title {
  font-size: 80px;
  font-weight: bold;
  margin-bottom: 6px; }
  @media (max-width: 767px) {
    .page-title {
      font-size: 35px;
      margin-bottom: 14px; } }

.page-description {
  max-width: 465px;
  font-size: 18px;
  margin-bottom: 59px; }
  @media (max-width: 767px) {
    .page-description {
      font-size: 14px; } }

p {
  font-size: 14px;
  margin-bottom: 21px; }

.footer-social-links .social-link {
  display: inline-block;
  text-align: center;
  line-height: 40px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #faaf43;
  color: #0e1033;
  margin-right: 16px;
  transition: all 0.3s ease-in-out; }
  .footer-social-links .social-link:last-child {
    margin-right: 0; }
  .footer-social-links .social-link:hover {
    text-decoration: none;
    background-color: #0e1033;
    color: #faaf43; }


`;
