import * as actionTypes from "../constants/authConstants";

export const userReducer = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.GET_USER:
      return {
        auth: action.payload
      };

    case actionTypes.LOGIN_CONFIRMED_ACTION:
      return {
        auth: action.payload,
    };

    case actionTypes.LOGOUT_ACTION:
      return {
        auth: null
    };
    default:
      return state;
  }
}

export const signUpReducer = (state = { auth: null }, action) => {
  switch (action.type) {

    

    case actionTypes.SIGNUP_REQUEST:
      return {
        loading: true
      };
    case actionTypes.SIGNUP_REQUEST_SUCCESS:
      return {
        auth: action.payload,
        loading: false,
      };
    case actionTypes.SIGNUP_REQUEST_FAIL:
      return {
        loading: false,
        auth:null,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const signInReducer = (state = { auth: null }, action) => {
    switch (action.type) {
      case actionTypes.SIGNIN_REQUEST:
        return {
          loading: true
        };
      case actionTypes.SIGNIN_REQUEST_SUCCESS:
        return {
          auth: action.payload,
          loading: false,
        };
      case actionTypes.SIGNIN_REQUEST_FAIL:
        return {
          loading: false,
          auth:null,
          error: action.payload,
        };
      default:
        return state;
    }
  };